import axios from 'axios';
import { BASE_URL,API_BASE_URL } from '../helper/globals';
import {useDispatch,useSelector} from 'react-redux';
import {store} from '../store';

const state = () => {
  return store.getState();
}
async function checkPhone(loginInput){
  const { phone } = loginInput;
  console.log(loginInput);
  return new Promise((resolve, reject) => { // don't forget to use dispatch here!
  axios.post(API_BASE_URL + 'verifyLogin', loginInput)
      .then((response) => {
        const json = response.data;
        if (json.success) { // response success checking logic could differ
          resolve(json.data);
        } else {
          reject({error:'Something wrong'});
        }
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};


async function logout(loginInput){
  const { phone } = loginInput;
  console.log(loginInput);
  return new Promise((resolve, reject) => { // don't forget to use dispatch here!
  axios.get(API_BASE_URL + 'logout', {'params': loginInput})
      .then((response) => {
        const json = response.data;
        console.log(json);
        if (json.success) { // response success checking logic could differ
          resolve(json.data);
        } else {
          reject({error:'Something wrong'});
        }
      })
      .catch((err) => {
        console.log(err);
        reject({error:err});
      });
  });
};

async function checkOTP(loginInput){
  const { phone,otp } = loginInput;
  console.log(loginInput);
  return new Promise((resolve, reject) => { // don't forget to use dispatch here!
    return fetch(API_BASE_URL + 'verifyOTP', {
      method: 'POST',
      headers: {  // these could be different for your API call
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginInput),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json.success) { // response success checking logic could differ
          resolve(json.data);
        } else {
          console.log(json);
          reject({error:'Something wrong'});
        }
      })
      .catch((err) => {
        console.log(err);
        reject({error:err});
      });
  });
};

async function getNotifications({user}){
  return new Promise(function(resolve, reject) {
          const query_params = {
              api_token:user.apiToken,
              limit:10,
              search:'notifiable_id:'+user.id,
              searchFields:'notifiable_id:=',
              orderBy:'id',
              sortedBy:'desc',
          };
          console.log('notification load');
          axios.get(BASE_URL + 'api/notifications', { 'params':query_params})
          .then((response) => {
              const json = response.data;
              if (json.success) { // response success checking logic could differ
                  resolve(json.data);
              } else {
                  reject({error:'Something wrong'});
              }
          })
          .catch((error) => {
              console.log(error);
              reject({error});
          })
  });
};


async function getCounts(){
  return new Promise(function(resolve, reject) {
          const query_params = {
              api_token:state().user.currentUser.apiToken,
          };
          console.log('notification load');
          axios.get(BASE_URL + 'api/manager/dashboard/'+state().user.currentUser.id, { 'params':query_params})
          .then((response) => {
              const json = response.data;
              if (json.success) { // response success checking logic could differ
                  resolve(json.data);
              } else {
                  reject({error:'Something wrong'});
              }
          })
          .catch((error) => {
              console.log(error);
              reject({error});
          })
  });
};


async function updateUser(userData){
  return new Promise(function(resolve, reject) {
          userData.api_token = state().user.currentUser.apiToken;
          console.log('notification load');
          axios.post(BASE_URL + 'api/manager/users/'+state().user.currentUser.id,userData)
          .then((response) => {
              const json = response.data;
              if (json.success) { // response success checking logic could differ
                  resolve(json.data);
              } else {
                  reject({error:'Something wrong'});
              }
          })
          .catch((error) => {
              console.log(error);
              reject({error});
          })
  });
};

async function addReference(user_id,parentPhone){
    return new Promise(function(resolve, reject) {
        const formData = {
            api_token:state().user.currentUser.apiToken,
            user_id,
            parent_phone:parentPhone
        }

        axios.post(BASE_URL + 'api/admin/add-reference/',formData)
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:'Something wrong'});
                }
            })
            .catch((error) => {
                reject(error.response);
            })
    });
};
async function removeReference(user_id){
    return new Promise(function(resolve, reject) {
        const formData = {
            api_token:state().user.currentUser.apiToken,
            user_id
        }

        axios.post(BASE_URL + 'api/admin/remove-reference/',formData)
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:'Something wrong'});
                }
            })
            .catch((error) => {
                reject(error.response);
            })
    });
};
async function getUsers({page,term,limit,kyc}){
    const pageNum = page && page != undefined && page != '' ? page:1;
    const per_page = limit ?? 10;
    const api_token = state().user.currentUser.apiToken;
    const offset = (pageNum - 1) * per_page;

    const query_params = {
        api_token,
        offset,
        limit:per_page,
        orderBy:'id',
        sortedBy:'desc',
        with:'kyc',
        search:'',
        searchFields:''
      }


    if(term){
        query_params.search = 'phone:'+term+';';
        query_params.searchFields = 'phone:like;';
    }

    if(kyc != 'undefined' && kyc){
      query_params.search += 'kyc.status:'+kyc+";";
      query_params.searchFields = 'kyc.status:=;';
    }

    return new Promise(function(resolve, reject) {
        axios.get(BASE_URL + 'api/admin/manage-users', { 'params':query_params})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};

async function getUserData(user_id){
    const api_token = state().user.currentUser.apiToken;
    const query_params = {
        api_token,
        with:'bankaccounts;kyc;parent'
    }

    return new Promise(function(resolve, reject) {
        axios.get(BASE_URL + 'api/admin/manage-users/'+user_id, { 'params':query_params})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};


async function updateKyc(user_id,status){
  return new Promise(function(resolve, reject) {
      const formData = {
          api_token:state().user.currentUser.apiToken,
          user_id,
          status
      }

      axios.post(BASE_URL + 'api/admin/kyc/update',formData)
          .then((response) => {
              const json = response.data;
              if (json.success) { // response success checking logic could differ
                  resolve(json.data);
              } else {
                  reject({error:'Something wrong'});
              }
          })
          .catch((error) => {
              reject(error.response);
          })
  });
};

async function updateBankAccount(user_id,bank_account_id,status){
  return new Promise(function(resolve, reject) {
      const formData = {
          api_token:state().user.currentUser.apiToken,
          user_id,
          bank_account_id,
          status
      }

      axios.post(BASE_URL + 'api/admin/bankaccount/update',formData)
          .then((response) => {
              const json = response.data;
              if (json.success) { // response success checking logic could differ
                  resolve(json.data);
              } else {
                  reject({error:'Something wrong'});
              }
          })
          .catch((error) => {
              reject(error.response);
          })
  });
};
export const userService = {
  checkOTP,
  checkPhone,
  getNotifications,
  logout,
  getCounts,
  updateUser,
  getUsers,
  getUserData,
  removeReference,
  addReference,
  updateKyc,
  updateBankAccount
};
