import React, {useState} from 'react';
const NkTbItem = (props) => {
        return (
            <>
                <div className="nk-tb-item">

                    <div className="nk-tb-col tb-col-sm">
                         <span className="title">{props.product.code}</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-lead">{props.product.name} - {props.product.capacity + props.product.unit}</span>
                    </div>
                    <div className="nk-tb-col">
                        <input style={{width:"200px"}} type={'number'} value={props.product.stock} onChange={(e) => props.onStockChange(e.target.value)} className="form-control"></input>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                        <select style={{width:"200px"}} onChange={(e) => props.onTypeChange(e.target.value)} name="" id="" className={'form-control'}>
                            <option value="Increase">Increase</option>
                            <option value="decrease">Decrease</option>
                        </select>
                    </div>
                    <div className="nk-tb-col ">
                        <ul className="nk-tb-actions justify-start">
                            <li className="mr-n1">
                                <button type="button"  onClick={props.removeProduct} className="btn btn-danger">
                                    <em className="icon ni ni-cross"></em>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
}

export default NkTbItem;
