import React, {Component, useState} from 'react';
import {useSelector} from "react-redux";
import AutoCompleteProducts from "../../../components/inputs/AutoCompleteProducts";
import {adjustmentService} from "../../../../services/service-adjustment";

const StatisticsColThree = () => {
    const [selectedMarket,setSelectedMarket] = useState('');
    const [selectedProduct,setSelectedProduct] = useState('');
    const [stockResult,setStockResult] = useState(null);
    const [stockLoading,setStockLoading] = useState(false);
    const {market} = useSelector(state => state);

    const getStock = () => {
        if(!selectedMarket || !selectedProduct){
            return;
        }
        setStockLoading(true);
        adjustmentService.getProductStock(selectedProduct.id,selectedMarket)
            .then((data) => setStockResult(data))
            .finally(() => setStockLoading(false));
    }
    return (
            <>
                <div className="col-xxl-6 col-md-6">
                    <div className="card card-full overflow-hidden">
                        <div className="nk-ecwg nk-ecwg7 h-100">
                            <div className="card-inner flex-grow-1">
                                <div className="card-title-group mb-4">
                                    <div className="card-title">
                                        <h6 className="title">Product Stock</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {stockResult && <table className="table">
                                            <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Type</th>
                                                <th scope="col">Quantity</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th scope="row">Increase</th>
                                                <td>{stockResult.increase}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Decrease</th>
                                                <td>{stockResult.decrease}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Sales</th>
                                                <td>{stockResult.ordersCount}</td>
                                            </tr>
                                            <tr>
                                                <th>Stock Left</th>
                                                <td>{stockResult.stockLeft}</td>
                                            </tr>
                                            </tbody>
                                        </table>}
                                        { !stockResult && <><div className="form-group">
                                            <label className="form-label" htmlFor="sale-price">Branch</label>
                                            <div className="form-control-wrap">
                                                <select onChange={(e) => setSelectedMarket(e.target.value)} className={'form-control'}>
                                                    <option>Warehouse</option>
                                                    {market.marketData.map((market,index) => <option value={market.id}>{market.name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="sale-price">Product</label>
                                            <div className="form-control-wrap">
                                                {!selectedProduct && <AutoCompleteProducts selected_value={(val) => setSelectedProduct(val)}/>}
                                                {selectedProduct && <>
                                                <span className="badge badge-success">{selectedProduct.id + ' '+ selectedProduct.name}</span>
                                                <span onClick={() => setSelectedProduct('')} className="badge badge-danger">X</span>
                                                </>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button onClick={getStock} disabled={stockLoading} className="btn btn-success">Check Stock</button>
                                        </div></>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-6 col-md-6">
                    <div className="card h-100">
                        <div className="card-inner">
                            <div className="card-title-group mb-2">
                                <div className="card-title">
                                    <h6 className="title">Store Statistics</h6>
                                </div>
                            </div>
                            <ul className="nk-store-statistics">
                                <li className="item">
                                    <div className="info">
                                        <div className="title">Orders</div>
                                        <div className="count">N/A</div>
                                    </div>
                                    <em className="icon bg-primary-dim ni ni-bag"></em>
                                </li>
                                <li className="item">
                                    <div className="info">
                                        <div className="title">Customers</div>
                                        <div className="count">N/A</div>
                                    </div>
                                    <em className="icon bg-info-dim ni ni-users"></em>
                                </li>
                                <li className="item">
                                    <div className="info">
                                        <div className="title">Products</div>
                                        <div className="count">N/A</div>
                                    </div>
                                    <em className="icon bg-pink-dim ni ni-box"></em>
                                </li>
                                <li className="item">
                                    <div className="info">
                                        <div className="title">Categories</div>
                                        <div className="count">N/A</div>
                                    </div>
                                    <em className="icon bg-purple-dim ni ni-server"></em>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
    );
}

export default StatisticsColThree;
