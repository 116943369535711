import React, {useState} from 'react';

const PageHeading = (props) => {
        const [term,setTerm] = useState(props.term ?? '');
        const [status,setStatus] = useState(props.status ?? '');
        const applyFilter = () => {
            props.applyFilter({term,status});
        }

        return (
            <>
                <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between">
                        <div className="nk-block-head-content">
                            <h3 className="nk-block-title page-title">{props.name}</h3>
                        </div>
                        {props.name == "Dashboard" &&
                        <div className="nk-block-head-content">
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                   data-target="pageMenu"><em className="icon ni ni-more-v"></em></a>
                                <div className="toggle-expand-content" data-content="pageMenu">
                                    {/*<ul className="nk-block-tools g-3">
                                        <li>
                                            <div className="drodown">
                                                <a href="#"
                                                   className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                                                   data-toggle="dropdown"><em
                                                    className="d-none d-sm-inline icon ni ni-calender-date"></em><span><span
                                                    className="d-none d-md-inline">Last</span> 30 Days</span><em
                                                    className="dd-indc icon ni ni-chevron-right"></em></a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <ul className="link-list-opt no-bdr">
                                                        <li><a href="#"><span>Last 30 Days</span></a></li>
                                                        <li><a href="#"><span>Last 6 Months</span></a></li>
                                                        <li><a href="#"><span>Last 1 Years</span></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="nk-block-tools-opt"><a href="#"
                                                                              className="btn btn-primary"><em
                                            className="icon ni ni-reports"></em><span>Reports</span></a>
                                        </li>
        </ul>*/}
                                </div>
                            </div>
                        </div>
                        }
                        {props.name == "Orders" &&
                        <div className="nk-block-head-content">
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                   data-target="pageMenu"><em className="icon ni ni-more-v"></em></a>
                                <div className="toggle-expand-content" data-content="pageMenu">
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <div className="form-control-wrap">
                                                <div className="form-icon form-icon-right">
                                                    <em className="icon ni ni-search"></em>
                                                </div>
                                                <input type="text" className="form-control" onChange={(val) => setTerm(val.target.value)} id="default-04"
                                                       placeholder="Quick search by id" value={term}/>
                                            </div>
                                        </li>
                                        <li>
                                        <select class="select2 form-control" onChange={(val) => setStatus(val.target.value)} id="order_status_id" name="order_status_id">
                                            <option value="">Select Status</option>
                                            <option value="1" selected={status == '1'} >Order Received</option>
                                            <option value="2" selected={status == '2'}>Preparing</option>
                                            <option value="3" selected={status == '3'}>Ready</option>
                                            <option value="4" selected={status == '4'}>On the Way</option>
                                            <option value="5" selected={status == '5'}>Delivered</option>
                                        </select>
                                        </li>
                                        <li>
                                            <button className="btn btn-primary" onClick={applyFilter}>Filter</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </>
        );
}

export default PageHeading;
