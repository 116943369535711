import React, {Component} from 'react';

const SectionTableBody = (props) => {
    return (
        <li className="d-flex justify-between">
            <div className="nk-tb-col tb-col-md">
                <span className="tb-sub">{props.item.name}</span>
            </div>
            <div className="nk-tb-col tb-col-md">
                <span className="tb-sub">{props.item.parameters ? 'Parameters added':'NO parameters'}</span>
            </div>
            <div className="nk-tb-col tb-col-md">
                        <span className="tb-sub">
                            <button type="button" onClick={props.editElement}
                                    className={'btn btn-secondary mr-1'}>Edit</button>
                            <button type="button" onClick={props.removeElement}
                                    className={'btn btn-danger'}>Remove</button>
                        </span>
            </div>
        </li>
    );
}

export default SectionTableBody;
