import {
    SET_LOGIN,
    SET_LOGIN_PROCESS,
    SET_LOGOUT,
    SET_NOTIFICATIONS,
} from '../constants/user';
let user = JSON.parse(localStorage.getItem('user'));
const initialState = {
    currentUser: user ? user:{
        id:null,
        name:null,
        email:null,
        phone:null,
        reference:null,
        apiToken:null,
        address:null,
        bio:null,
        image:null,
    },
    notifications:[],
    loginProcess:false,
  };
  
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
      case SET_LOGIN:
        return {
          ...state,
          currentUser: action.data,
        };
    case SET_LOGOUT:
            return {
              initialState
    };
    case SET_LOGIN_PROCESS:
        return {
              ...state,
              loginProcess:action.process
        }
    case SET_NOTIFICATIONS:
        return {
            ...state,
            notifications:action.data
        }

      default:
        return state;
    }
}