import React, {useEffect,useState} from 'react';
import AsideCard from './components/AsideCard';
import {useSelector,useDispatch} from 'react-redux';
import {userService} from '../../../services/service-user';
import {setLoginState} from '../../../store/actions/user.actions';
import MapContainer from '../../components/common/MapContainer';
import {toast } from 'react-toastify';
const Profile = () => {
        const {user} = useSelector((state) => state);
        const dispatch = useDispatch();
        const [userData,setUserDATA] = useState(user.currentUser);
        const [saving,setSaving] = useState(false);
        useEffect(() => {

        },[]);

        const saveUser = () => {
            const userUpdateData = {
                name:userData.name,
                email:userData.email,
                address:userData.address,
                bio:userData.bio,
            };
            userService.updateUser(userUpdateData)
            .then((data) => {
                toast.success("User information updated!");
                dispatch(setLoginState({
                    id:data.id,
                    name:data.name,
                    email:data.email,
                    phone:data.phone,
                    reference:data.reference,
                    address:data.address?data.address.value:'',
                    apiToken:data.api_token,
                    bio:data.bio?.value,
                }));
            });
        }
        
        return (
            <div className="nk-content-inner">
                <div className="nk-content-body">
                    <div className="nk-block">
                        <div className="card">
                            <div className="card-aside-wrap">
                                <div className="card-inner card-inner-lg">
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="personal">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-between">
                                                    <div className="nk-block-head-content">
                                                        <h4 className="nk-block-title">Personal Information</h4>
                                                        <div className="nk-block-des">
                                                            <p>Basic info, like your name and address, that you use on
                                                                Our Platform.</p>
                                                        </div>
                                                    </div>
                                                    <div className="nk-block-head-content align-self-start d-lg-none">
                                                        <a href="#" className="toggle btn btn-icon btn-trigger mt-n1"
                                                            data-target="userAside"><em className="icon ni ni-menu-alt-r"></em></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="nk-block">
                                                <div className="nk-data">
                                                    <div className="row g-3">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Full Name</label>
                                                                <input type="text" value={userData.name} onChange={(event) => setUserDATA({...userData,name:event.target.value})} className="form-control" placeholder="Add your name..."/>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Phone Number</label>
                                                                <input type="text" value={userData.phone} disabled className="form-control" placeholder="Your Phone Number"/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row  g-3">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Email</label>
                                                                <input type="email" value={userData.email} onChange={(event) => setUserDATA({...userData,email:event.target.value})} className="form-control" placeholder="Your Email Address..."/>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Address</label>
                                                                <input type="text" value={userData.address} onChange={(event) => setUserDATA({...userData,address:event.target.value})} className="form-control" placeholder="Your Address"/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row g-3">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>Short Bio</label>
                                                                <textarea value={userData.bio} className="form-control" onChange={(event) => setUserDATA({...userData,bio:event.target.value})} placeholder="Tell us something about you!">
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <button type="button" onClick={saveUser} className="btn btn-success">Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
            
                                    </div>
                                </div>
            
                                <AsideCard  tab="basic"/>
            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default Profile;
