import React,{useState} from 'react';
import {adjustmentService} from "../../../services/service-adjustment";
import AutoSuggest from "react-autosuggest";
const AutoCompleteProducts = (props) => {
    const [value,setValue] = useState('');
    const [items,setItems] = useState([]);
    const searchProducts = (term) => {
        adjustmentService.searchProducts(term)
            .then((res) => {
                setItems(res);
            })
            .catch((err) => {

            });
    }

    return(
        <div className="autoCompleteCol">
            <AutoSuggest
                suggestions={items}
                onSuggestionsClearRequested={() => setItems([])}
                onSuggestionsFetchRequested={({ value }) => {
                    //console.log(value);
                    setValue(value);
                    searchProducts(value);
                }}
                onSuggestionSelected={(event, { suggestionValue }) => {
                  //  console.log('event',event);
                    console.log("Selected: ", suggestionValue);
                }}
                getSuggestionValue={(suggestion) => {
                    props.selected_value(suggestion);
                    return '';
                }}
                renderSuggestion={suggestion =>
                    <span className="suggestions">{suggestion.name + ' - '+suggestion.capacity+suggestion.unit} (Pack of: {suggestion.package_items_count})</span>
                }
                inputProps={{
                    placeholder: "Search Product name or Barcode",
                    value: value,
                    onChange: (_, { newValue, method }) => {
                        setValue(newValue);
                    }
                }}
                highlightFirstSuggestion={true}
            />
        </div>
    );
}
export default AutoCompleteProducts;