import React,{useEffect,useState} from 'react';
import Autocomplete from 'react-google-autocomplete';
import {Map, InfoWindow, Marker, GoogleApiWrapper,Listing} from 'google-maps-react';
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"

const MAP_KEY = 'AIzaSyD2vSMnKnPqJ2Fefy_8cU_V2O7IeDDRAfc';

export const MapContainer = (props) => {
    const [state,setState] = useState({
       showingInfoWindow:false,
       activeMarker:{},
       selectedPlace:{},
    });
    const [placeVal,setPlaceVal] = useState(props.address ?? '');
    const [position,setPosition] = useState({
      lat:props.latitude != undefined ? parseFloat(props.latitude):30.7353912,
      lng:props.longitude != undefined ? parseFloat(props.longitude):76.7695381,
    });

    useEffect(() => {
      mapReady();
    },[props])

    const onMarkerClick = (pp, marker, e) =>{
      setState({...state,
        selectedPlace: pp,
        activeMarker: marker,
        showingInfoWindow: true
      });
    }

    const onMapClicked = (pp) => {
      if (state.showingInfoWindow) {
        setState({...state,
          showingInfoWindow: false,
          activeMarker: null
        })
      }
    };

    const handleNoResult = () => {

    }

    const onLocationChange = (place) => {
        if(place && place.geometry != undefined) {
            setPosition(place.geometry.location);
            setPlaceVal(place.formatted_address);
            props.handleLocation({
                place_id:place.place_id,
                address:place.formatted_address,
                latitude:place.geometry.location.lat(),
                longitude:place.geometry.location.lng()
            });
        }
    }

    const statusHandle = (status) => {

    }

    const onMarkerDragEnd = (mapProps,maps,coords) => {
        const { latLng } = coords;
        const {google} = mapProps;
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({
            latLng
        }, function(responses) {
            if (responses && responses.length > 0) {
                onLocationChange(responses[0]);
            }
        });
    }

    const mapReady = (mp,map) => {
      const { google } = props;

      if (!google || !map) return;

      map.setCenter(position);
      map.setZoom(17);
    }
      return (
        <>

            {/*<Autocomplete
            style={{width: '100%',height:'calc(2.125rem + 2px)',padding:'0.4375rem 1rem',fontSize:'0.8125rem',fontWeight:400,lineHeight:'1.25rem',color:'#3c4d62',border:'1px solid #dbdfea'}}
            defaultValue={props.address != undefined ? props.address:''}
            onPlaceSelected={(place) => {
              if(place && place.geometry != undefined){
              setPosition(place.geometry.location);

              if(props.onLocationChange && props.onLocationChange !== undefined){
                props.onLocationChange({
                  place_id:place.place_id,
                  address:place.formatted_address,
                  latitude:place.geometry.location.lat(),
                  longitude:place.geometry.location.lng()
                });
              }

            }
            }}
        />*/}
            <ReactGoogleMapLoader
                params={{
                    key: MAP_KEY,
                    libraries: "places,geocode",
                }}
                render={googleMaps =>
                    googleMaps && (
                        <ReactGooglePlacesSuggest
                            googleMaps={googleMaps}
                            autocompletionRequest={{
                                input: placeVal,

                            }}
                            // Optional props
                            displayPoweredByGoogle={false}
                            onNoResult={handleNoResult}
                            onSelectSuggest={onLocationChange}
                            onStatusUpdate={onLocationChange}
                            textNoResults="No results" // null or "" if you want to disable the no results item
                            customRender={prediction => (
                                <div className="customWrapper">
                                    {prediction
                                        ? prediction.description
                                        : "My custom no results text"}
                                </div>
                            )}
                        >
                            <input
                                type="text"
                                value={placeVal}
                                className={'form-control'}
                                placeholder="Search a location"
                                onChange={(event) => setPlaceVal(event.target.value)}
                            />
                        </ReactGooglePlacesSuggest>
                    )
                }
            />
        <Map
        style={{width: '100%', height: '100%', position: 'relative'}}
        className={'map'}
        center={position}
        onReady={mapReady}
        google={props.google}
            onClick={onMapClicked}>
          <Marker onClick={onMarkerClick} draggable={true} position={position}  onDragend={(t, map, coord) => onMarkerDragEnd(t,map,coord)} />

         { /*<InfoWindow
            marker={state.activeMarker}
            visible={state.showingInfoWindow}>
              <div>
                <p>{state.selectedPlace.name}</p>
              </div>
         </InfoWindow>*/}
        </Map>
        </>
      )
}

export default GoogleApiWrapper({
  apiKey: (MAP_KEY)
})(MapContainer)
