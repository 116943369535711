/**
 * Production api
 */
export const BASE_URL = "https://system.sanbros.org/";
export const API_BASE_URL = "https://system.sanbros.org/api/admin/";
export const API_BASE_URL_ROOT = "https://system.sanbros.org/api/";
/**
 * Development api
 */
// export const BASE_URL = "https://store.spacium.dev/";
// export const API_BASE_URL = "https://store.spacium.dev/api/manager/";
// export const API_BASE_URL_ROOT = "https://store.spacium.dev/api/";
/**
* Localhost api
*/
//export const BASE_URL = "http://127.0.0.1:8000/";
//export const API_BASE_URL = "http://127.0.0.1:8000/api/admin/";
//export const API_BASE_URL_ROOT = "http://127.0.0.1:8000/api/";
