import React, {useState,useEffect} from 'react';
import Pagination from "react-js-pagination";
import NkTbHead from "./components/NkTbHead";
import NkTbItem from "./components/NkTbItem";
import EditUser from "./EditUser";
import { Link,useHistory,useLocation} from 'react-router-dom';
import {marketService} from '../../../services/service-markets';
import {useSelector} from 'react-redux';
import queryString from 'query-string';
import {initialService} from "../../../services/service-initial";
import Select from "react-select";
import {userService} from "../../../services/service-user";
const Users = () => {
    const history = useHistory();
    const locChange = useLocation();
    const {market} = useSelector(state => state);
    const [loading,setLoading] = useState(false);
    const [users,setUsers] = useState([]);
    const [totalUsers,setTotalUsers] = useState(0);
    const params = queryString.parse(window.location.search);
    const [state,setState] = useState({
        limit:20,
        page:params.page && params.page != undefined ? parseInt(params.page):1,
        term:params.term && params.term != undefined ? params.term:'',
        kycStatus:params.kyc && params.kyc != undefined ? params.kyc:'',
    });

    const [tempState,setTempState] = useState({
        limit:20,
        page:params.page && params.page != undefined ? parseInt(params.page):1,
        term:params.term && params.term != undefined ? params.term:'',
        kycStatus:params.kyc && params.kyc != undefined ? params.kyc:'',
    });
    useEffect(() => {
      getUsers();
    }, []);

    useEffect(() => {
        const params = queryString.parse(locChange.search);
        setState({
            ...state,
            page:params.page && params.page != undefined ? parseInt(params.page):1,
            term:params.term && params.term != undefined ? params.term:'',
            kycStatus:params.kyc && params.kyc != undefined ? params.kyc:'',
        });
    },[locChange])

    useEffect(() => {
        getUsers();
    }, [state]);

    const applyFilter = () => {

        history.push(`/users?page=1&term=${tempState.term}&kyc=${tempState.kycStatus}`);

        //getOrders(1);
    }

    const getUsers = (pagenum = '') => {
        setLoading(true);
        userService.getUsers({page:pagenum ? pagenum:state.page,term:state.term,limit:state.limit,kyc:state.kycStatus})
        .then(res => {
            setLoading(false);
            setUsers(res.users);
            if(res.total_users){
            setTotalUsers(res.total_users);
            }
        },
        err => {
            setLoading(false);
            alert(err.message);
        });
    }

    const handlePageChange = (page) => {
        history.push(`/users?page=${page}&term=${state.term}&brand=${state.brand}&status=${state.status}&kyc=${state.kycStatus}`);
        setState({...state,page});
    }



    const nextPage = (page) => {
        setState({
            ...state,
            page:state.page+1
        });
    }

    const prevPage = (page) => {
        if(state.page <= 1){
            return;
        }
        setState({
            ...state,
            page:state.page-1
        });
    }

        return (
            <div className="nk-content-inner">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">Users</h3>
                            </div>
                            <div className="nk-block-head-content">
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                       data-target="pageMenu"><em className="icon ni ni-more-v"></em></a>
                                    <div className="toggle-expand-content" data-content="pageMenu">
                                        <ul className="nk-block-tools g-3">
                                            <li>
                                                <div className="form-control-wrap">
                                                    <div className="form-icon form-icon-right">
                                                        <em className="icon ni ni-search"></em>
                                                    </div>
                                                    <input type="text" value={tempState.term} onChange={(event) => setTempState({...tempState,term:event.target.value})} className="form-control" id="default-04"
                                                           placeholder="Search by name"/>
                                                </div>
                                            </li>
                                            <li className="nk-block-tools-opt">
                                                <select value={tempState.kycStatus} onChange={(e) => setTempState({...tempState,kycStatus:e.target.value})} className={'form-control'}>
                                                    <option value="">KYC STATUS</option>
                                                    <option value='0'>Pending</option>
                                                    <option value='1'>Verified</option>
                                                    <option value='2'>Rejected</option>
                                                </select>
                                            </li>
                                            <li className="nk-block-tools-opt">
                                                <button onClick={applyFilter} data-target="addProduct"
                                                   className="toggle btn btn-icon btn-primary d-md-none"><em
                                                    className="icon ni ni-search"></em></button>
                                                <button onClick={applyFilter} data-target="addProduct"
                                                   className="toggle btn btn-primary d-none d-md-inline-flex"><em
                                                    className="icon ni ni-search"></em><span>Filter</span></button>
                                            </li>
                                            <li className="nk-block-tools-opt">
                                            <div class="drodown"><a href="#" class="dropdown-toggle btn btn-icon btn-primary" data-toggle="dropdown"
                                                    aria-expanded="false"><em class="icon ni ni-plus"></em></a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    {/*<ul className="link-list-opt no-bdr">*/}
                                                    {/*    <li><Link to="/products/addProduct"><span>Add Product</span></Link></li>*/}
                                                    {/*    <li><Link to="/inventory"><span>Copy from Inventory</span></Link></li>*/}
                                                    {/*</ul>*/}
                                                </div>
                                            </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block">
                        <div className="nk-tb-list is-separate mb-3">
                            <NkTbHead/>
                            {users.length > 0 && users.map((value,index) => <NkTbItem user={value}/>)}


                        </div>
                        {users.length <= 0 && !loading && <div className={'text-center'}>No users Found</div>}
                        {loading && <div className={'text-center'}> <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div></div>}
                        {!loading && totalUsers > 0 && <div>
                            <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            firstPageText="« First Page"
                            lastPageText="Last Page »"
                            nextPageText="Next ⟩"
                            prevPageText="⟨ Prev"
                            activePage={state.page}
                            itemsCountPerPage={state.limit}
                            totalItemsCount={totalUsers}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            />
                        </div>}
                    </div>
                </div>
            </div>
        );
}

export default Users;
