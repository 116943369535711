import React, {useState} from 'react';
import {parse} from "query-string";
const NkTbItem = (props) => {
        return (
            <>
                <div className="nk-tb-item">
                    <div className="nk-tb-col tb-col-sm">
                         <span className="title">{props.product.code}</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-lead">
                            {props.product.name + ' - '+ props.product.capacity + props.product.unit}
                        </span>
                    </div>
                    <div className="nk-tb-col">
                        <input style={{width:"200px"}} type={'number'} value={props.product.stock} onChange={(e) => props.onStockChange(e.target.value)} className="form-control"></input>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                        <div className="d-flex flex-row align-items-center">
                            Rs.
                            <input
                                style={{width:"80px"}}
                                type={'number'}
                                value={props.product.price}
                                onChange={(e) => props.onPriceChange(e.target.value)}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                        Rs. {parseInt(props.product.price) * parseInt(props.product.stock)}
                    </div>
                    <div className="nk-tb-col ">
                        <ul className="nk-tb-actions justify-start">
                            <li className="mr-n1">
                                <button type="button"  onClick={props.removeProduct} className="btn btn-danger">
                                    <em className="icon ni ni-cross"></em>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
}

export default NkTbItem;
