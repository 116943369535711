import React, {Component} from 'react';
import { Link } from 'react-router-dom';
const statusBadge = (order) => {
    if(!order.active){
        return (<span className="badge badge-sm badge-dot has-bg badge-danger d-none d-mb-inline-flex">Cancelled</span>);
    }


    switch(order.order_status_id){
        case 1:
            return (<span className="badge badge-sm badge-dot has-bg badge-info d-none d-mb-inline-flex">Order Received</span>);
            break;
        case 2:
            return (<span className="badge badge-sm badge-dot has-bg badge-info d-none d-mb-inline-flex">Preparing</span>);
            break;
        case 3:
            return (<span className="badge badge-sm badge-dot has-bg badge-primary d-none d-mb-inline-flex">Ready</span>);
            break;
        case 4:
            return (<span className="badge badge-sm badge-dot has-bg badge-warning d-none d-mb-inline-flex">On the way</span>);
            break;
        case 5:
            return (<span className="badge badge-sm badge-dot has-bg badge-success d-none d-mb-inline-flex">Delivered</span>);
            break;

    }
}

const NkTbItem = (props) => {
        return (
            <>
                <div key={props.order.id} className="nk-tb-item">
                    <div className="nk-tb-col">
                        <span className="tb-lead">
                        <Link to={{pathname: "/orderDetail/"+props.order.id,}}>
                        #{props.order.id}
                        </Link>
                        </span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                        <span className="tb-sub">{props.order.updated_at}</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="dot bg-warning d-mb-none"></span>
                        {statusBadge(props.order)}
                    </div>
                    <div className="nk-tb-col tb-col-sm">
                        <span className="tb-sub">{props.order.user.name}</span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                            <span className="tb-sub text-primary">{props.order.product_orders.length} Items</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-lead">Rs. {props.order.payment.price}</span>
                    </div>
                    <div className="nk-tb-col nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1">
                        { props.order.order_status.id != 5 && props.order.active && <li className="nk-tb-action-hidden">
                                <a href="javascript:;" onClick={() => props.updateStatus(props.order.id,5)}
                                    className="btn btn-icon btn-trigger btn-tooltip"
                                    title="Mark as Delivered">
                                <em className="icon ni ni-truck"></em></a></li>}
                               <li className="nk-tb-action-hidden">
                                    <Link to={{pathname: "/orderDetail/"+props.order.id,}}>
                                        <em className="icon ni ni-eye"></em>
                                    </Link>
                                </li>
                        </ul>
                    </div>
                </div>
            </>
        );
}

export default NkTbItem;
