import React, {Component} from 'react';

class NkTbHead extends Component {
    render() {
        return (
            <>
                <div className="nk-tb-item nk-tb-head">

                    <div className="nk-tb-col"><span>Product Image</span></div>
                    <div className="nk-tb-col tb-col-sm"><span>Name</span></div>
                    <div className="nk-tb-col"><span>Price</span></div>
                    <div className="nk-tb-col"><span>Capacity</span></div>
                    <div className="nk-tb-col"><span>Total Stock</span></div>
                    <div className="nk-tb-col tb-col-md">Category</div>
                    <div className="nk-tb-col tb-col-md">#</div>
                </div>
            </>
        );
    }
}

export default NkTbHead;
