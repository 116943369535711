import React, {useEffect,useState} from 'react';
import {Link,useHistory} from "react-router-dom";
import { setUserLogout } from '../../../store/actions/user.actions';
import {setLoginState} from '../../../store/actions/user.actions';
import {userService} from '../../../services/service-user';
import {useDispatch} from 'react-redux';
const OtpPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [user,setUser] = useState(null);
    const [otp,setOtp] = useState('');
    const [loading,setLoading] = useState(false);
    useEffect(() => {
        checkTempSession();
    }, [])


    const submitOtp = () => {
        if(!otp){
            alert( 'OTP is required to continue!');
            return;
        }
        setLoading(true);
        userService.checkOTP({phone:user.phone,otp:otp}).then((data) => {
            dispatch(setLoginState({
                id:data.id,
                name:data.name,
                email:data.email,
                phone:data.phone,
                reference:data.reference,
                address:data.address?data.address.value:'',
                apiToken:data.api_token,
                bio:data.bio?.value,
            }));
            history.push('/');
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            alert(err.error);
            setLoading(false);
        })
    }

        const checkTempSession = () => {
            const tempSession = sessionStorage.getItem('temp_u');

            if(!tempSession){
                history.push('/login');
            }
            setUser(JSON.parse(tempSession));
        }

        return (
                                    <div className="nk-block nk-block-middle nk-auth-body">
                                        <div className="brand-logo pb-5">
                                            <a href="html/index.html" className="logo-link">
                                                <img className="logo-light logo-img logo-img-lg" src="../../../../assets/images/vendor.png"
                                                     srcSet="../../../../assets/images/vendor.png" alt="logo"/>
                                                <img className="logo-dark logo-img logo-img-lg"
                                                     src="../../../../assets/images/vendor.png"
                                                     srcSet="../../../../assets/images/vendor.png" alt="logo-dark"/>
                                            </a>
                                        </div>
                                        <div className="nk-block-head">
                                            <div className="nk-block-head-content">
                                                <h5 className="nk-block-title">Verify OTP</h5>
                                                <div className="nk-block-des">
                                                    <p>Please enter your one time password which has been sent to your registered mobile number: {user?.phone}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <form action="#">
                                            <div className="form-group">
                                                <div className="form-label-group">
                                                    <label className="form-label" htmlFor="default-01">Enter OTP</label>
                                                </div>
                                                <input type="text" name="otp" onChange={(evt) => setOtp(evt.target.value)} className="form-control form-control-lg"
                                                       id="default-01"
                                                       placeholder="One time password ****"/>
                                            </div>
                                            <div className="form-group">
                                                {/*<button className="btn btn-lg btn-primary btn-block">Sign in</button>*/}
                                                <Link onClick={submitOtp} className="btn btn-lg btn-primary btn-block">Verify OTP</Link>
                                            </div>
                                        </form>
                                    </div>
        );
}

export default OtpPage;
