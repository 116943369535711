import {API_BASE_URL} from '../../helper/globals';
import {
    SET_MARKET_DATA,
    SET_MARKET_PRODUCTS,
    SET_MARKET_ORDERS,
    SET_MARKET_CLOSED,
    RESET_MARKET_PRODUCTS,
} from '../../store/constants/user';


export const setMarketState = (marketData) => {
    return {
      type: SET_MARKET_DATA,
      data: marketData,
    };
};
export const setMarketProducts = (marketData) => {
    return {
      type: SET_MARKET_PRODUCTS,
      data: marketData,
    };
};

export const resetMarketProducts = () => {
    return {
      type: RESET_MARKET_PRODUCTS,
    }
}
export const setMarketOrders = (orderData) => {
    return {
      type: SET_MARKET_ORDERS,
      data: orderData,
    };
};

export const setMarketClosed = (status) => {
  return {
    type: SET_MARKET_CLOSED,
    data: status,
  };
};