import React, {useState,useEffect} from 'react';
import Pagination from "react-js-pagination";
import NkTbHead from "./list/NkTbHead";
import NkTbItem from "./list/NkTbItem";
import AddProduct from "./components/AddProduct";
import { Link,useHistory} from 'react-router-dom';
import {adjustmentService} from '../../../services/service-adjustment';
import {useSelector} from 'react-redux';
import queryString from 'query-string';
import {toast } from 'react-toastify';
import {transferService} from "../../../services/service-transfers";

const TransferList = () => {
    const history = useHistory();
    const [loading,setLoading] = useState(false);
    const [transfers,setTransfers] = useState([]);
    const [totalTransfers,setTotalTransfers] = useState(0);
    const params = queryString.parse(window.location.search);
    const [state,setState] = useState({
        limit:20,
        page:params.page && params.page != undefined ? params.page:1,
        term:params.term && params.term != undefined ? params.term:'',
        status:params.status && params.status != undefined ? params.status:'',
    });

    useEffect(() => {
        getTransfers();
    }, []);

    useEffect(() => {
        getTransfers();
    }, [state]);

    const applyFilter = () => {

        history.push(`/transfer?page=${state.page}&term=${state.term}&status=${state.status}`);
        setState({
            ...state,
            page:1,
        });
    }

    const getTransfers = (pagenum = '') => {
        setLoading(true);
        transferService.getTransfers({page:pagenum ? pagenum:state.page,limit:state.limit})
            .then(res => {
                    setLoading(false);
                    setTransfers(res.transfers);
                    if(res.counts){
                        setTotalTransfers(res.counts);
                    }
                },
                err => {
                    setLoading(false);
                    alert(err.message);
                });
    }

    const handlePageChange = (page) => {
        setState({...state,page});
    }


    const nextPage = (page) => {
        setState({
            ...state,
            page:state.page+1
        });
    }

    const prevPage = (page) => {
        if(state.page <= 1){
            return;
        }
        setState({
            ...state,
            page:state.page-1
        });
    }
    return (
        <div className="nk-content-inner">
            <div className="nk-content-body">
                <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between">
                        <div className="nk-block-head-content">
                            <h3 className="nk-block-title page-title">Transfers</h3>
                        </div>
                        <div className="nk-block-head-content">
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                   data-target="pageMenu"><em className="icon ni ni-more-v"></em></a>
                                <div className="toggle-expand-content" data-content="pageMenu">
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <div className="form-control-wrap">
                                                <div className="form-icon form-icon-right">
                                                    <em className="icon ni ni-search"></em>
                                                </div>
                                                <input type="text" value={state.term} onChange={(event) => setState({...state,term:event.target.value})} className="form-control" id="default-04"
                                                       placeholder="Search by name"/>
                                            </div>
                                        </li>
                                        <li className="nk-block-tools-opt">
                                            <button onClick={applyFilter} data-target="addProduct"
                                                    className="toggle btn btn-icon btn-primary d-md-none"><em
                                                className="icon ni ni-search"></em></button>
                                            <button onClick={applyFilter} data-target="addProduct"
                                                    className="toggle btn btn-primary d-none d-md-inline-flex"><em
                                                className="icon ni ni-search"></em><span>Filter</span></button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-block">
                    <div className="nk-tb-list is-separate mb-3">
                        <NkTbHead/>

                        {transfers.length > 0 && transfers.map((value,index) =>
                            <NkTbItem transfer={value} detail={'/transferDetail/'+value.id}/>
                        )}
                    </div>
                    {!loading && totalTransfers > 0 && <div>
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            firstPageText="« First Page"
                            lastPageText="Last Page »"
                            nextPageText="Next ⟩"
                            prevPageText="⟨ Prev"
                            activePage={state.page}
                            itemsCountPerPage={state.limit}
                            totalItemsCount={totalTransfers}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                        />
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default TransferList;
