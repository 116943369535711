import React, {useEffect, useState} from 'react';
import {sectionService} from "../../../services/service-section";
import queryString from "query-string";

const CreateSection = (props) => {
    const [creating,setCreating] = useState(false);
    const section_id = props.match.params.id;
    const [sectionData,setSectionData] = useState({
        name:'',
        title:'',
        type:'',
        content_type:'',
        active:0,
        content_method:'',
        countdown_to: 0,
        content_limit: 1
    });

    useEffect(() => {
        console.log('section found',section_id);
        if(section_id && section_id !== undefined){
            console.log('klsdasjdsad');
            getSectionData();
        }
    },[]);

    const getSectionData = () => {
        sectionService.getSectionData(section_id)
            .then((res) => {
                console.log(res);
                setSectionData({
                    name:res.name,
                    title:res.title,
                    type:res.type,
                    content_type:res.content_type,
                    content_method: res.content_method,
                    content_limit: res.content_limit,
                    active:res.active,
                    countdown_to:res.countdown_to,
                });
            })
            .catch((err) => alert(err.response));
    }

    const createSection = () => {
        setCreating(true);
            sectionService.saveSection(sectionData)
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => alert(err.response.message));
    }

    const updateSection = () => {
        setCreating(true);
        sectionService.updateSection(sectionData,section_id)
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => alert(err.response.message));
    }

    return (
        <div className="nk-content-inner">
            <div className="nk-content-body">
                <div className="nk-block">
                    <div className="card">
                        <div className="card-aside-wrap">
                            <div className="card-inner card-inner-lg">
                                <div className="tab-content">
                                    <div className="tab-pane active" id="personal">
                                        <div className="nk-block-head nk-block-head-lg">
                                            <div className="nk-block-between">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">Add Section</h4>

                                                </div>
                                                <div className="nk-block-head-content align-self-start d-lg-none">
                                                    <a href="#" className="toggle btn btn-icon btn-trigger mt-n1"
                                                       data-target="userAside">
                                                        <em className="icon ni ni-menu-alt-r"></em>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="nk-block">
                                            <div className="nk-data">
                                                <div className="row g-3">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Section Name</label>
                                                            <input type="text" value={sectionData.name} className="form-control"
                                                                   placeholder="Add section name..." onChange={(event) => setSectionData({
                                                                ...sectionData,
                                                                name:event.target.value
                                                            })}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Section Title (visible on app side)</label>
                                                            <input type="text" value={sectionData.title} className="form-control"
                                                                   placeholder="Add section title..." onChange={(event) => setSectionData({
                                                                ...sectionData,
                                                                title:event.target.value
                                                            })}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Section Type</label>
                                                            <select className={'form-control'} value={sectionData.type} onChange={(event) => setSectionData({
                                                                ...sectionData,
                                                                type:event.target.value
                                                            })}>
                                                                <option value="">Select Section Type</option>
                                                                <option value="slider">Slider</option>
                                                                <option value="carousel">Carousel</option>
                                                                <option value="tile">Tiles</option>
                                                                <option value="listed_banner">Listed Banner</option>
                                                                <option value="collage">Collage</option>
                                                                <option value="single">Single</option>
                                                                <option value="categories_slider">Categories Slider</option>
                                                                <option value="brands_slider">Brands Slider</option>
                                                                <option value="products_slider">Products Slider</option>
                                                                <option value="deals">Deals</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row  g-3">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Content Type</label>
                                                            <select className={'form-control'} value={sectionData.content_type} onChange={(event) => setSectionData({
                                                                ...sectionData,
                                                                content_type:event.target.value
                                                            })}>
                                                                <option value="">Select content type</option>
                                                                <option value="sublist">Sublist</option>
                                                                <option value="dynamic">Dynamic</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Method</label>
                                                            <input type="text" value={sectionData.content_method}  className="form-control"
                                                                   placeholder="Method Name" onChange={(event) => setSectionData({
                                                                ...sectionData,
                                                                content_method:event.target.value
                                                            })}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Content Limit</label>
                                                            <input type="number" className="form-control"
                                                                   placeholder="Limit" value={sectionData.content_limit}  onChange={(event) => setSectionData({
                                                                ...sectionData,
                                                                content_limit:event.target.value
                                                            })}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Countdown To</label>
                                                            <input type="number" className="form-control"
                                                                   placeholder="Countdown To" value={sectionData.countdown_to}  onChange={(event) => setSectionData({
                                                                ...sectionData,
                                                                countdown_to:event.target.value
                                                            })}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row g-3">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <button type="button" onClick={section_id !==undefined ? updateSection:createSection} disabled={creating} className="btn btn-success">{creating ? 'Creating...':'Create'}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateSection;
