import {
    SET_MARKET_DATA,
    SET_MARKET_PRODUCTS,
    SET_MARKET_ORDERS,
    SET_REST_MARKET_DATA,
    SET_MARKET_CLOSED,
    RESET_MARKET_PRODUCTS,
} from '../constants/user';

const initialState = {
    marketData: [],
    marketProducts:[],
    marketOrders:[],
};
  
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
      case SET_MARKET_DATA:
        return {
          ...state,
          marketData: action.data,
        };
      case SET_MARKET_PRODUCTS:
          return {
            ...state,
            marketProducts: state.marketProducts.concat(action.data),
          };
      case RESET_MARKET_PRODUCTS:
          return {
            ...state,
            marketProducts: []
          };
      case SET_MARKET_ORDERS:
          return {
              ...state,
              marketOrders: action.data,
          };
      case SET_MARKET_CLOSED:
          return {
            ...state,
            marketData: {...state.marketData,closed:action.data}
          };
      case SET_REST_MARKET_DATA: 
        return initialState;
      default:
        return state;
    }
}