import React, {useState} from 'react';
const ItemDetail = (props) => {

    const getUnit = () => {
        return props.variation ? props.variation.capacity+props.variation.unit:props.product.capacity+props.product.unit;
    }


        return (
            <>
                <div className="nk-tb-item">

                    <div className="nk-tb-col tb-col-sm">
                         <span className="title">{props.variation ? props.variation.code:props.product.code}</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-lead">{props.product.name} - {getUnit()}</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-lead">{props.item.stock}</span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                        <span className="tb-lead">{props.item.cost}</span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                        <span className="tb-lead">{props.item.cost * props.item.stock}</span>
                    </div>

                </div>
            </>
        );
}

export default ItemDetail;
