import React, {Component, useEffect, useState} from 'react';
import {userService} from "../../../services/service-user";
import {Link} from "react-router-dom";

const kycstatus = (kyc) => {
    if(!kyc){
        return <span className="badge badge-danger text-black-50">Not Added</span>
    }

    if(kyc && kyc.status < 1){
        return <span className="badge badge-warning">Pending Verification</span>
    }

    if(kyc && kyc.status === 1){
        return <span className="badge badge-success">Verified</span>
    }
    if(kyc && kyc.status === 2){
        return <span className="badge badge-danger">Rejected</span>
    }
}

const EditUser = (props) => {
    const [user,setUser] = useState(null);
    const [loading,setLoading] = useState(false);
    const user_id = props.match.params.id;
    const [parentPhone,setParentPhone] = useState('');
    useEffect(() => {
        getUserData();
    },[]);

    const getUserData = () => {
        userService.getUserData(user_id)
            .then((res) => {
                setUser(res);
            });
    }

    const addReference = () => {
        if(!parentPhone){
            alert('Please add parent phone number first');
            return;
        }
        var code = prompt("Please enter your pin");
        if(code !== '1470'){
            alert('Wrong Pin');
            return;
        }

        userService.addReference(user_id,parentPhone)
            .then((res) => window.location.reload())
                .catch((err) => alert(err.data.message));

    }

    const removeReference = () => {
        var code = prompt("Please enter your pin");
        if(code !== '1470'){
            alert('Wrong Pin');
            return;
        }

        userService.removeReference(user_id)
            .then((res) => window.location.reload())
            .catch((err) => alert(err.data.message));
    }

    const updateKyc = (status) => {
        userService.updateKyc(user_id,status)
        .then((res) => getUserData())
        .catch((err) => console.log(err));
    }

    const updateBank = (bank_id,status) => {
        userService.updateBankAccount(user_id,bank_id,status)
        .then((res) => getUserData())
        .catch((err) => console.log(err));
    }

        return (
            <>
            { user && <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <header className="card-header">User: {user_id}</header>
                            <div className="card-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-8"><h5 className="mb-2"><strong>Name:</strong></h5>
                                                <h6 className="mb-0">User ID: #{user_id}</h6><p className="mb-0 fs--1">
                                                    <strong>Name:</strong> {user.name}</p>
                                                <div className="text-500 fs--1"><strong>Phone:</strong> {user.phone}</div>
                                                <div><strong className="mr-2">KYC Status: </strong>{kycstatus(user.kyc)}</div>
                                                <button className="btn btn-outline-info" type="button">Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                           <div className="form-group">
                                               <label htmlFor="">Parent Reference</label><br/>
                                               {user.parent &&
                                                   <>
                                                        <div className={'badge badge-danger'}>{user.parent.phone}</div>
                                                        <button className="btn btn-danger btn-sm  ml-2" onClick={removeReference}>Remove reference</button>
                                                   </>
                                                }

                                               {!user.parent && <>
                                                   <input type="text" className="form-control" onChange={(ev) => setParentPhone(ev.target.value)} placeholder={'Parent Phone number'}/>
                                                   <button className="btn btn-primary btn-sm  ml-2" onClick={addReference}>Add reference</button>
                                               </>}
                                           </div>
                                        </div>
                                    </div>
                                </div>
                                {user.kyc && <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-4"><h5 className="mb-3 fs-0">KYC</h5><p
                                                className="mb-0 fs--1"><strong>Pan: </strong><span>{user.kyc.pan_card}</span></p></div>
                                            <div className="col-lg-4"><h5 className="mb-3 fs-0">&nbsp;</h5><p
                                                className="mb-0 fs--1"><strong>Aadhar Number: </strong><span>{user.kyc.aadhar_card}</span></p></div>
                                            <div className="col-lg-12"><h5 className="mb-3 fs-0">KYC Documents</h5>
                                                <div className="doc_thumbs mb-2">
                                                    <ul className={'d-flex flex-row'}>
                                                        <li className={'mr-1'}>
                                                            <a target={'_blank'} href={user.kyc.pan_card_image[0].url} className={'btn btn-info btn-sm'}>Download PanCard</a>
                                                        </li>
                                                        <li className={'mr-1'}>
                                                            <a target={'_blank'} href={user.kyc.aadhar_front[0].url} className={'btn btn-info btn-sm'}>Download Aadhar Front</a>
                                                        </li>
                                                        <li className={'mr-1'}>
                                                            <a target={'_blank'} href={user.kyc.aadhar_back[0].url} className={'btn btn-info btn-sm'}>Download Aadhar Back</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {user.kyc && user.kyc.status < 1 && <div className="col-lg-4">
                                                <div className="btn-group" role="group">
                                                    <button className="btn btn-outline-success" onClick={() => updateKyc('approve')} type="button">Approve
                                                        KYC
                                                    </button>
                                                    <button className="btn btn-outline-danger" onClick={() => updateKyc('reject')} type="button">Reject
                                                        KYC
                                                    </button>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>}

                            </div>
                        </div>
                        <div className="card">
                            <header className="card-header">Bank Accounts<small className="text-muted"> list</small>
                            </header>
                            <div className="card-body">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Account Number</th>
                                        <th>Name</th>
                                        <th>Bank Name</th>
                                        <th>IFSC</th>
                                        <th>Cheque / Passbook</th>
                                        <th>Status</th>
                                        <th>#</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {!user.bankaccounts || user.bankaccounts.length <= 0 && <tr className="no-data">
                                        <td colSpan="8" className="text-center">No Bank Accounts to show</td>
                                    </tr>}
                                    {user.bankaccounts && user.bankaccounts.map((bank) => {
                                        return <tr>
                                            <td>
                                                {bank.id}
                                            </td>
                                            <td>
                                                {bank.account_number}
                                            </td>
                                            <td>
                                                {bank.name}
                                            </td>
                                            <td>
                                                {bank.bank_name}
                                            </td>
                                            <td>
                                                {bank.ifsc}
                                            </td>
                                            <td>
                                                <a target={'_blank'} href={bank.has_media ? bank.media[0].url:''} className={'btn btn-primary'} alt="bank cheque">View Attachment</a>
                                            </td>
                                            <td>
                                                {kycstatus(bank)}
                                            </td>
                                            <td>
                                                <div className="btn-group" role="group">
                                                    <button className="btn btn-outline-success" onClick={() => updateBank(bank.id,'approve')} type="button">Approve
                                                        
                                                    </button>
                                                    <button className="btn btn-outline-danger" onClick={() => updateBank(bank.id,'reject')} type="button">Reject
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div> }
            </>
        );
}

export default EditUser;
