import React, {Component} from 'react';

const NkTbHead = (props) => {
        return (
            <>
                <div className="nk-tb-item nk-tb-head">
                    <div className="nk-tb-col nk-tb-col-check">
                        <div className="custom-control custom-control-sm custom-checkbox notext">
                            <input type="checkbox" className="custom-control-input" onChange={() => props.handleAll()} id="uid"/>
                            <label className="custom-control-label" htmlFor="uid"></label>
                        </div>
                    </div>
                    <div className="nk-tb-col"><span>Product Image</span></div>
                    <div className="nk-tb-col tb-col-sm"><span>Name</span></div>
                    <div className="nk-tb-col"><span>Price</span></div>
                    <div className="nk-tb-col"><span>Capacity</span></div>
                    <div className="nk-tb-col tb-col-md">Category</div>
                    <div className="nk-tb-col tb-col-md">#</div>
                </div>
            </>
        );
}

export default NkTbHead;
