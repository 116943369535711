import React, {Component} from 'react';
import Pagination from "../../components/pagination/Pagination";
import NkTbHead from "./components/NkTbHead";
import NkTbItem from "./components/NkTbItem";
import AddProduct from "./components/AddProduct";
import PageHeading from "../../components/header/PageHeading";

class Customer extends Component {
    render() {
        return (
            <div className="nk-content-inner">
                <div className="nk-content-body">
                    <PageHeading name={"Customers"}/>
                    <div className="nk-block">
                        <div className="nk-tb-list is-separate mb-3">
                            <NkTbHead/>
                            <NkTbItem/>
                        </div>
                        <Pagination/>
                    </div>
                    <AddProduct/>
                </div>
            </div>
        );
    }
}

export default Customer;
