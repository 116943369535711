import React,{useRef,useState} from 'react';
import { ReactSortable } from "react-sortablejs";
const GalleryImageSelector = (props) => {
    const inputFileRef = useRef( null );
    const [imageUrl,setImageUrl] = useState(props.imageUrl || '');
    const handleInputSelect = () =>{
        inputFileRef.current.value = '';
        inputFileRef.current.click();
    }

    const handleFileChange = (e) => {
       // _handleImageChange(e);
        if(props.onChange) props.onChange(e,'gallery');
    }

    const removeImage = () => {
        setImageUrl('');
        inputFileRef.current.value = '';
        if(props.removeImage) props.removeImage();
    }

    const setNewList = (list) => {
        props.sortList(list);
    }

    return (
        <div className="gallery-selector float-left w-100">
            <input ref={inputFileRef} type="file" name="file" style={{display:'none'}} onChange={handleFileChange} />
            <div className="card ml-1" style={{'max-width':'300px',background:'rgb(249 249 249)', border:'1px solid #dddddd'}}>
                <div className="container">
                <div className="row">
                    <div className="col-md-4 p-1">
                        <div onClick={handleInputSelect} className="imagePreview">
                            {props.loading ? <img src={"/assets/images/loader.gif"}/>:<img src={imageUrl || "/assets/images/add-image.png"}/>}
                        </div>
                    </div>
                    <ReactSortable tag="ul" list={props.galleryImages} setList={setNewList}>
                    {props.galleryImages.length > 0 && props.galleryImages.map((img,index) => {
                        return <li key={index} className="gallery-img-dv">
                            <button onClick={() => props.removeImage(index)} className={'gallery-img-remove'}><em className="icon ni ni-cross"></em></button>
                            <img src={img.imageUrl}/>
                        </li>
                    })}
                    </ReactSortable>
                </div>
                </div>
            </div>
        </div>
    )
}

export default GalleryImageSelector;