import React, {Component} from 'react';
import { Link } from 'react-router-dom';

const kycstatus = (kyc) => {
    if(!kyc){
        return <span className="badge badge-danger text-black-50">Not Added</span>
    }

    if(kyc && kyc.status < 1){
        return <span className="badge badge-warning">Pending Verification</span>
    }

    if(kyc && kyc.status === 1){
        return <span className="badge badge-success">Verified</span>
    }
    if(kyc && kyc.status === 2){
        return <span className="badge badge-danger">Rejected</span>
    }
}

const NkTbItem = (props) => {


        return (
            <>
                <div className="nk-tb-item">

                    <div className="nk-tb-col tb-col-sm">
                         <span className="title"><Link to={"/users/editUser/"+props.user.id}>{props.user.name}</Link></span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-lead">{props.user.phone}</span>
                    </div>

                    <div className="nk-tb-col">
                        <span className="tb-lead">{kycstatus(props.user.kyc)}</span>
                    </div>

                    <div className="nk-tb-col nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1 my-n1">
                            <li className="mr-n1">
                               <Link to={'/users/editUser/'+props.user.id} className="btn primary">View</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
}

export default NkTbItem;
