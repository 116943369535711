import React, {useState,useEffect} from 'react';
import {Link} from "react-router-dom";
import {setLoginState} from '../../../store/actions/user.actions';
import {userService} from '../../../services/service-user';
import {useSelector,useDispatch} from 'react-redux';
import { useHistory } from "react-router-dom";
const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {currentUser} = useSelector(state => state.user);
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [loading,setLoading] = useState(false);

    const login = () => {
        if(!email){
            alert('Email is required to login');
            return;
        }

        setLoading(true);
        userService.checkPhone({email,password}).then((data) => {
            dispatch(setLoginState({
                id:data.id,
                name:data.name,
                email:data.email,
                phone:data.phone,
                reference:data.reference,
                address:data.address?data.address.value:'',
                apiToken:data.api_token,
                bio:data.bio?.value,
            }));

            history.push('/');
            setLoading(false);
        }).catch((err) => {
            alert(err.message);
            setLoading(false);
        })
    }

        return (
                                    <div className="nk-block nk-block-middle nk-auth-body">
                                        <div className="brand-logo pb-5">
                                            <a href="html/index.html" className="logo-link">
                                                <img className="logo-light logo-img logo-img-lg" src="../../../../assets/images/vendor.png"
                                                     srcSet="../../../../assets/images/vendor.png" alt="logo"/>
                                                    <img className="logo-dark logo-img logo-img-lg"
                                                         src="../../../../assets/images/vendor.png"
                                                         srcSet="../../../../assets/images/vendor.png" alt="logo-dark"/>
                                            </a>
                                        </div>
                                        <div className="nk-block-head">
                                            <div className="nk-block-head-content">
                                                <h5 className="nk-block-title">Sign-In</h5>
                                                <div className="nk-block-des">
                                                    <p>Please enter your registered mobile number</p>
                                                </div>
                                            </div>
                                        </div>
                                        <form action="#">
                                            <div className="form-group">
                                                <div className="form-label-group">
                                                    <label className="form-label" htmlFor="default-01">Admin Email</label>
                                                </div>
                                                <input type="text" name="phone" onChange={(evt) => setEmail(evt.target.value)} className="form-control form-control-lg"
                                                       id="default-01"
                                                       placeholder="Email"/>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-label-group">
                                                    <label className="form-label" htmlFor="default-01">Password</label>
                                                </div>
                                                <input type="text" name="phone" onChange={(evt) => setPassword(evt.target.value)} className="form-control form-control-lg"
                                                       id="default-01"
                                                       placeholder="password"/>
                                            </div>
                                            <div className="form-group">
                                                {/*<button className="btn btn-lg btn-primary btn-block">Sign in</button>*/}
                                                <Link onClick={login} className="btn btn-lg btn-primary btn-block">{loading ? 'Verifying...':'Login'}</Link>
                                            </div>
                                        </form>
                                    </div>


        );
}

export default Login;
