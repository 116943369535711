import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
const Sidebar = () => {
        const {market} = useSelector(state => state);

        return (
            <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
                <div className="nk-sidebar-element nk-sidebar-head">
                    <div className="nk-sidebar-brand">
                            <Link to="/" className="logo-link">
                                <img className="logo-light logo-img" src="../../../../assets/images/vendor.png"
                                     srcSet="../../../../assets/images/logo-space.png" alt="logo"/>
                                <img className="logo-dark logo-img" src="../../../../assets/images/vendor.png"
                                     srcSet="../../../../assets/images/vendor.png" alt="logo-dark"/>
                            </Link>
                    </div>
                    <div className="nk-menu-trigger mr-n2">
                        <Link href="#" className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
                              data-target="sidebarMenu"><em className="icon ni ni-arrow-left"></em></Link>
                        <Link href="#" className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex"
                              data-target="sidebarMenu"><em className="icon ni ni-menu"></em></Link>
                    </div>
                </div>
                <div className="nk-sidebar-element">
                    <div className="nk-sidebar-content">
                        <div className="nk-sidebar-menu" data-simplebar>
                            <ul className="nk-menu">
                                <li className="nk-menu-item">
                                    <Link to="/" className="nk-menu-link">
                                        <span className="nk-menu-icon"><em
                                            className="icon ni ni-dashboard-fill"></em></span>
                                        <span className="nk-menu-text">Dashboard</span>
                                    </Link>
                                </li>
                                <li className="nk-menu-item">
                                    <Link to="/products" className="nk-menu-link">
                                        <span className="nk-menu-icon">
                                            <em className="icon ni ni-package-fill"></em>
                                        </span>
                                        <span className="nk-menu-text">Products</span>
                                    </Link>
                                </li>
                                <li className="nk-menu-item">
                                    <Link to="/expiring" className="nk-menu-link">
                                        <span className="nk-menu-icon">
                                            <em className="icon ni ni-package-fill"></em>
                                        </span>
                                        <span className="nk-menu-text">Expiring Stocks</span>
                                    </Link>
                                </li>
                                <li className="nk-menu-item">
                                    <Link to="/products/addProduct" className="nk-menu-link">
                                        <span className="nk-menu-icon"><em
                                            className="icon ni ni-box"></em></span>
                                        <span className="nk-menu-text">Add Product</span>
                                    </Link>
                                </li>
                                <li className="nk-menu-item">
                                    <Link to="/adjustments" className="nk-menu-link">
                                        <span className="nk-menu-icon"><em
                                            className="icon ni ni-stack-overflow"></em></span>
                                        <span className="nk-menu-text">Adjustments</span>
                                    </Link>
                                </li>
                                <li className="nk-menu-item">
                                    <Link to="/addAdjustment" className="nk-menu-link">
                                        <span className="nk-menu-icon"><em
                                            className="icon ni ni-plus"></em></span>
                                        <span className="nk-menu-text">Add Adjustments</span>
                                    </Link>
                                </li>


                                <li className="nk-menu-item">
                                    <Link to="/transferList" className="nk-menu-link">
                                        <span className="nk-menu-icon"><em
                                            className="icon ni ni-truck"></em></span>
                                        <span className="nk-menu-text">Transfer</span>
                                    </Link>
                                </li>
                                <li className="nk-menu-item">
                                    <Link to="/createTransfer" className="nk-menu-link">
                                        <span className="nk-menu-icon"><em
                                            className="icon ni ni-trend-up"></em></span>
                                        <span className="nk-menu-text">Create Transfer</span>
                                    </Link>
                                </li>
                                <li className="nk-menu-item">
                                    <Link to="/users" className="nk-menu-link">
                                        <span className="nk-menu-icon"><em
                                            className="icon ni ni-users"></em></span>
                                        <span className="nk-menu-text">Users</span>
                                    </Link>
                                </li>

                                <li className="nk-menu-item">
                                    <Link to="/section" className="nk-menu-link">
                                        <span className="nk-menu-icon"><em
                                            className="icon ni ni-truck"></em></span>
                                        <span className="nk-menu-text">Section</span>
                                    </Link>
                                </li>
                                <li className="nk-menu-item">
                                    <Link to="/createSection" className="nk-menu-link">
                                        <span className="nk-menu-icon"><em
                                            className="icon ni ni-trend-up"></em></span>
                                        <span className="nk-menu-text">Create Section</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );

}

export default Sidebar;
