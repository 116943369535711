import React, {Component} from 'react';

class NkTbHead extends Component {
    render() {
        return (
            <>
                <div className="nk-tb-item nk-tb-head">
                    <div className="nk-tb-col nk-tb-col-check">
                        <div className="custom-control custom-control-sm custom-checkbox notext">
                            <input type="checkbox" className="custom-control-input" id="uid"/>
                            <label className="custom-control-label" htmlFor="uid"></label>
                        </div>
                    </div>
                    <div className="nk-tb-col tb-col-sm"><span>Name</span></div>
                    <div className="nk-tb-col"><span>SKU</span></div>
                    <div className="nk-tb-col"><span>Price</span></div>
                    <div className="nk-tb-col"><span>Stock</span></div>
                    <div className="nk-tb-col tb-col-md"><span>Category</span></div>
                    <div className="nk-tb-col tb-col-md"><em
                        className="tb-asterisk icon ni ni-star-round"></em></div>
                    <div className="nk-tb-col nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1 my-n1">
                            <li className="mr-n1">
                                <div className="dropdown">
                                    <a href="#" className="dropdown-toggle btn btn-icon btn-trigger"
                                       data-toggle="dropdown"><em
                                        className="icon ni ni-more-h"></em></a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <ul className="link-list-opt no-bdr">
                                            <li><a href="#"><em className="icon ni ni-edit"></em><span>Edit Selected</span></a>
                                            </li>
                                            <li><a href="#"><em className="icon ni ni-trash"></em><span>Remove Selected</span></a>
                                            </li>
                                            <li><a href="#"><em className="icon ni ni-bar-c"></em><span>Update Stock</span></a>
                                            </li>
                                            <li><a href="#"><em
                                                className="icon ni ni-invest"></em><span>Update Price</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

export default NkTbHead;
