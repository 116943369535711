import React, {Component, useEffect, useState} from 'react';
import NkTbHead from "./components/NkTbHead";
import AutoCompleteProducts from "../../components/inputs/AutoCompleteProducts";
import {adjustmentService} from "../../../services/service-adjustment";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {API_BASE_URL} from "../../../helper/globals";
import axios from "axios";
import ItemDetail from "./components/ItemDetail";
import {transferService} from "../../../services/service-transfers";
import PrintTable from "./components/PrintTable";

const TransferDetail =(props)=> {
    const history = useHistory();
    const user = useSelector(state => state.user);
    const transfer_id = props.match.params.id;
    const [selectedProducts,setSelectedProducts] = useState([]);
    const [notes,setNotes] = useState('');
    const [saving,setSaving] = useState(false);
    const [selectedFile,setSelectedFile] = useState(null);
    const [uploading,setUploading] = useState(false);
    const [invalidProducts,setInvalidProducts] = useState([]);
    const [detailData,setDetailData] = useState(null);
    useEffect(() => {
        getTransferData();
    },[]);


    const getTransferData = () => {
        transferService.getTransferData(transfer_id)
            .then((res) => {
                setDetailData(res);
            })
            .catch((err) => {

            });
    }

    const getProduct = (item) => {
        return item.variation ? item.variation:item.product;
    }
    return (
        <div className="nk-content-inner">
            <div className="nk-content-body">
                <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-head-content">
                        <h5 className="nk-block-title">Detail ({detailData?.created_at})</h5>
                        <div className="nk-block-des">
                            <p>Transfer Detail</p>
                        </div>
                    </div>
                </div>
                <div className="nk-block">

                    <div className="card p-2 mb-2">
                        <div className="nk-block">
                            <div className="nk-tb-list is-separate mb-3">
                                <NkTbHead />
                                {detailData?.details.length > 0 && detailData?.details.map((item,index) =>
                                    <ItemDetail
                                        variation={item.variation}
                                        item={item}
                                        product={item.product}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="card p-2">
                        <div className="row g-3">
                            <div className="col-mb-12">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="sale-price">Notes</label>
                                    <div className="form-control-wrap">
                                        <span>{detailData?.notes}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card p-2 mb-2">
                        <div className="nk-block">
                            <div className="nk-tb-list is-separate mb-3">
                                <PrintTable shippingCharge={detailData?.shipping} totalCost={detailData?.GrandTotal} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
export default TransferDetail;
