import { API_BASE_URL_ROOT,BASE_URL } from '../helper/globals';
import axios from 'axios';

async function getFieldsData(){
    return new Promise(function(resolve, reject) {

            axios.get(API_BASE_URL_ROOT + 'fields', { 'params':{'orderBy':'updated_at','sortedBy': 'desc'}})
            .then((response) => {
                const json = response.data;
                console.log(json);
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};


async function getCategories(filterData = null){
    return new Promise(function(resolve, reject) {
            axios.get(API_BASE_URL_ROOT + 'categories',{params:filterData})
            .then((response) => {
                const json = response.data;
                console.log(json);
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    resolve(json.data);
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};

async function getParentCategories(){
    return new Promise(function(resolve, reject) {

        axios.get(API_BASE_URL_ROOT + 'parentcategories')
            .then((response) => {
                const json = response.data;
                console.log(json);
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    resolve(json.data);
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};


async function getBrands(filterData = null){
    return new Promise(function(resolve, reject) {
        axios.get(API_BASE_URL_ROOT + 'brands')
            .then((response) => {
                const json = response.data;
                console.log(json);
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    resolve(json.data);
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};

export const initialService = {
    getFieldsData,
    getCategories,
    getBrands,
    getParentCategories
};
