export const USER_UPDATE_DATA = 'USER_UPDATE_DATA';
export const SET_DEVICE_TOKEN = 'spacium-vendor/users/SET_DEVICE_TOKEN';

export const SET_LOGIN = 'spacium-vendor/users/LOGIN';
export const SET_LOGOUT = 'spacium-vendor/users/LOGOUT';
export const SET_LOGIN_PROCESS = 'spacium-vendor/users/LOGIN_PROCESS';



export const SET_MARKET_DATA = 'spacium-vendor/market/MARKET_DATA';
export const RESET_MARKET_PRODUCTS = 'spacium-vendor/market/RESET_MARKET_PRODUCTS';
export const SET_MARKET_CLOSED = 'spacium-vendor/market/MARKET_CLOSED';
export const SET_REST_MARKET_DATA = 'spacium-vendor/market/MARKET_DATA_RESET';
export const SET_MARKET_PRODUCTS = 'spacium-vendor/market/MARKET_PRODUCTS';
export const SET_MARKET_ORDERS = 'spacium-vendor/market/MARKET_ORDERS';

export const SET_NOTIFICATIONS = 'spacium-vendor/market/USER_NOTIFICATIONS';