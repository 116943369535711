import React from 'react';
import { Link} from 'react-router-dom';
const Adjustments = (props) => {
    return (
        <table className="table">
            <thead className="thead-light">
            <tr>
                <th scope="col">#</th>
                <th scope="col">Quantity</th>
                <th scope="col">Type</th>
                <th scope="col">Store</th>
                <th scope="col">Created At</th>
                <th scope="col">View</th>
            </tr>
            </thead>
            <tbody>
            {props.adjustments.length > 0 && props.adjustments.map((adjustment) => {
                return <tr>
                    <th scope="row">{adjustment.adjustment_id}</th>
                    <td>{adjustment.quantity}</td>
                    <td>{adjustment.type}</td>
                    <td>{adjustment.adjustment.market.name}</td>
                    <td>{adjustment.created_at}</td>
                    <td><Link to={"/adjustmentDetail/"+adjustment.adjustment_id}>View</Link></td>
                </tr>
            })}
            </tbody>
        </table>
    )
}

export default Adjustments;