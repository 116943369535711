import React, {useState,useEffect} from 'react';
import PageHeading from "../../components/header/PageHeading";
import {initialService} from '../../../services/service-initial';
import {marketService} from '../../../services/service-markets';
import {mediaService} from '../../../services/service-media';
import StandardProduct from './components/StandardProduct';
import { Link,useHistory} from 'react-router-dom';
import Select from 'react-select'
import {toast } from 'react-toastify';
import Adjustments from "./components/Adjustments";
import GalleryImageSelector from "../../components/common/GalleryImageSelector";
import AutoCompleteProducts from "../../components/inputs/AutoCompleteProducts";
const AddProduct = (props) => {
        const history = useHistory();
        const product_id = props.match.params.id;
        const [categories,setCategories] = useState([]);
        const [subCategories,setSubCategories] = useState([]);
        const [brands,setBrands] = useState([]);
        const [errors,setErrors] = useState({});
        const [saving,setSaving] = useState(false);
        const [productError,setProductError] = useState('');
        const [productLoading,setProductLoading] = useState(false);
        const [attachmentUploading,setAttachmentUploading] = useState(false);
        const [galleryImages,setGalleryImages] = useState([]);
        const [galleryUploading,setGalleryUploading] = useState(false);
        const [productData,setProductData] = useState({
            name:'',
            price:0,
            code:'',
            company_code:'',
            discountPrice:0,
            description:'',
            category:'',
            subcategory:'',
            capacity:'',
            unit:'',
            image:'',
            package_items_count:'',
            featured:false,
            active:true,
            variable:false,
            product_stock:0,
            imageUrl:null,
            stock:0,
            tags:'',
            group_code:'',
            variations:[
            ],
            adjustments:[],
            remove_variations:[]
        });
        useEffect(() => {
            getCategories();
            getBrands();
            if(product_id && product_id !== undefined){
                getProductData();
            }

        }, []);

        const addVariation = () => {
            let variations = productData.variations.concat({
                price: 0,
                capacity: 0,
                product_stock:0,
                discount_price: null,
                items: 0,
                unit: null,
                code:null,
                company_code:null,
            });
            setProductData({...productData,variations: variations});
        }

        const removeVariation = (index) => {
            let variation_data = productData.variations[index];
            let removing_var = [];
            if(typeof variation_data.id !== undefined) {
               removing_var = productData.remove_variations.concat(variation_data);
            }

            let variations = productData.variations.filter((it,i) => i !== index);

            setProductData({...productData,variations: variations,remove_variations: removing_var});
        }

        const getProductData = () => {
            setProductLoading(true);
            marketService.getProduct(product_id)
            .then((res) => {
                setProductData({...productData,
                    name:res.name,
                    capacity:res.capacity,
                    featured:res.featured,
                    code:res.code,
                    company_code: res.company_code,
                    unit:res.unit,
                    price:res.price,
                    discountPrice:res.discount_price,
                    description:res.description,
                    brand:res.brand ? {value:res.brand.id,label:res.brand.name}:null,
                    category:{value:res.category.id,label:res.category.name},
                    sub_category:res.subcategory ? {value:res.subcategory.id,label:res.subcategory.name}:null,
                    package_items_count:res.package_items_count,
                    product_stock:res.product_stock,
                    variable:res.variable,
                    variations: res.variations,
                    specifications: res.specifications,
                    tags:res.tags,
                    group_code:res.group_code,
                    stock:res.stock,
                    active:res.active,
                    adjustments: res.adjustments,
                });
                let images = res.media.map((img) => {
                    return {
                        image:img.custom_properties.uuid,
                        imageUrl:img.thumb
                    }
                })
                setGalleryImages(images);
                getSubCategories(res.category?.id);
            })
            .catch((err) => setProductError(err.message))
            .then(() => setProductLoading(false));
        }

        const getCategories = () => {
            initialService.getParentCategories()
            .then((res) => {
                setCategories(res.map((item,index) => {
                    return {
                        label:item.name,
                        value:item.id,
                    }
                    getSubCategories();
                }));
            });
        }

    const getSubCategories = (parent_id = '') => {
            const selectedParent = parent_id ? parent_id:productData.category.value;
            let query_data = {
            search: '',
            searchFields:'',
        };

        if(selectedParent){
            query_data.search = query_data.search + 'parent_id:'+selectedParent;
            query_data.searchFields = query_data.searchFields + 'parent_id:=;';
            query_data.parent_id = selectedParent;
        }

            initialService.getCategories(query_data)
                .then((res) => {
                    setSubCategories(res.map((item,index) => {
                        return {
                            label:item.name,
                            value:item.id,
                        }
                    }));
                });
    }


    const getBrands = () => {
        initialService.getBrands()
            .then((res) => {
                setBrands(res.map((item,index) => {
                    return {
                        label:item.name,
                        value:item.id,
                    }
                }));
            });
    }

        const validateForm = () => {
            let isValid = true;
            let err = [];
            if(productData.name === ''){
                err.name = 'Product name is required!';
                isValid = false;
            }


            if(!productData.variable){
                if(productData.price === ''){
                    err.price = 'Price is required!';
                    isValid = false;
                }

                if(productData.category === ''){
                    err.category = 'Please select at-least one category';
                    isValid = false;
                }

                if(productData.capacity === ''){
                    err.capacity = 'Please add capacity of product';
                    isValid = false;
                }

                if(productData.unit === ''){
                    err.unit = 'Please add product capacity unit (eg. Kg, g, L)';
                    isValid = false;
                }

                /*if(productData.package_items_count == ''){
                    err.package_items_count = 'Please package item count';
                    isValid = false;
                }*/
            }
            if(productData.variable && productData.variations.length <= 0){
                isValid = false;
                alert('Please add at least 1 variation');
            }
            setErrors(err);
            return isValid;
        }

        const getError = (name) => {
            return errors[name] !== undefined && errors[name] !== '' ? errors[name]:false;
        }

        const attachmentProcess = (image_type,type) => {
            image_type === 'featured' ? setAttachmentUploading(type):setGalleryUploading(type);
        }

        const onImageChange = (event,type = 'featured') => {
            if(event.target.files.length <= 0){
              return;
            }

            attachmentProcess(type,true);
            mediaService.uploadFile(event.target.files[0],type === 'featured' ? 'featured-image':'image')
            .then((res) => {
                console.log(res);
                if(type === 'featured'){
                    setProductData({...productData,image:res.uuid});
                    setProductData({...productData,imageUrl: res.media.url});
                }

                if(type === 'gallery'){
                    //let gallery_update = galleryImages.push({image:res.uuid,imageUrl:res.media.url});
                    setGalleryImages(
                        [
                            ...galleryImages,
                            {image:res.uuid,imageUrl:res.media.url}
                        ]
                    );
                }

            })
                .finally(() => attachmentProcess(type,false));
        }

        const removeImage = () => {
            setProductData({...productData,image:''});
        }
        const submitProduct = () => {
            if(!validateForm()){
                return;
            }
            setSaving(true);
            const formData = {
                name:productData.name,
                price:productData.price,
                unit:productData.unit,
                capacity:productData.capacity,
                discount_price:productData.discountPrice,
                featured:productData.featured,
                category_id:productData.category.value,
                sub_category_id:productData.sub_category.value,
                brand_id:productData.brand.value,
                description:productData.description,
                package_items_count:productData.package_items_count,
                product_stock:productData.product_stock,
                variable:productData.variable,
                variations:productData.variations,
                remove_variations:productData.remove_variations,
                code:productData.code,
                tags:productData.tags,
                specifications:productData.specifications,
                company_code:productData.company_code,
                group_code:productData.group_code,
                active:productData.active
            };
            formData.image = galleryImages;
            marketService.saveProduct(formData,product_id)
            .then((res) =>{
                toast.success("Product Saved")
                history.goBack();
            })
            .catch((err) => console.log(err))
            .then(() => setSaving(false));
        }

        const changeHandle = (data) => {
            setProductData(data);
        }

        const changeParentCategory = (event) => {
            setProductData({
                ...productData,
                category: event
            });
            getSubCategories(event.value);
        }

        const removeGalleryImage = (index) => {
            setGalleryImages(galleryImages.filter((img,i) => i !== index));
        }

        const sortGalleryList = (list) => {
            setGalleryImages(list);
        }

        return (
            <div className="nk-content-inner">
                <div className="nk-content-body">
                    <PageHeading name={"Manage Product"}/>
                    <div className="nk-block">
                        <div className="card">
                            <div className="card-inner">
                                <h5 className="card-title">Product Details</h5>

                                {!productLoading && !productError ? <>
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label for="product-name">Product Name</label>
                                                <div className="form-control-wrap">
                                                    <input type="text" value={productData.name} onChange={(event) => setProductData({...productData,name:event.target.value})} className={"form-control " + (getError('name') ? 'error':'')} id="product-name"
                                                           placeholder="Product Name"/>
                                                    {getError('name') && <span className="error">{getError('name')}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="product-category">Main Category</label>
                                                <div className="form-control-wrap">
                                                    <Select value={productData.category}
                                                            className={'selectable ' + (getError('category') ? 'error' : '')}
                                                            onChange={(event) => changeParentCategory(event)} options={categories}/>
                                                    {getError('category') &&
                                                    <span className="error">{getError('category')}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="product-category">Sub Category</label>
                                                <div className="form-control-wrap">
                                                    <Select value={productData.sub_category}
                                                            className={'selectable ' + (getError('sub_category') ? 'error' : '')}
                                                            onChange={(event) =>  setProductData({
                                                                ...productData,
                                                                sub_category: event
                                                            })} options={subCategories}/>
                                                    {getError('sub_category') &&
                                                    <span className="error">{getError('sub_category')}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="product-category">Brand</label>
                                                <div className="form-control-wrap">
                                                    <Select value={productData.brand}
                                                            className={'selectable ' + (getError('brand') ? 'error' : '')}
                                                            onChange={(event) => setProductData({
                                                                ...productData,
                                                                brand: event
                                                            })} options={brands}/>
                                                    {getError('brand') &&
                                                    <span className="error">{getError('brand')}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="product-description">Description</label>
                                                <div className="form-control-wrap">
                                                    <textarea type="text" value={productData.description}
                                                              onChange={(event) => setProductData({
                                                                  ...productData,
                                                                  description: event.target.value
                                                              })} className="form-control " id="product-description"
                                                              placeholder="Discription">
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="product-description">Product Tags (Please user comma (,) to separate)</label>
                                                <div className="form-control-wrap">
                                                    <textarea type="text" value={productData.tags}
                                                              onChange={(event) => setProductData({
                                                                  ...productData,
                                                                  tags: event.target.value
                                                              })} className="form-control " id="product-description"
                                                              placeholder="tags">
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div className="row g-3 align-center">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="site-off">Group Code (leave empty to autogenerate)</label>
                                                    {
                                                        productData.group_code ?
                                                            <div className={'w-100'}><span className="badge badge-primary">{productData.group_code}</span>
                                                            <span onClick={() => setProductData({
                                                                ...productData,
                                                                group_code: ''
                                                            })}  className="badge clickable badge-outline-primary">X</span></div>
                                                            :
                                                            <AutoCompleteProducts selected_value={(val) => setProductData({
                                                                ...productData,
                                                                group_code: val.group_code
                                                            })}/>

                                                    }
                                                </div>
                                            </div>

                                        </div>


                                        <StandardProduct
                                            getError={getError}
                                            productData={productData}
                                            changeHandle={changeHandle}
                                        />

                                    <div className="row mt-5">
                                            {/*<div className="form-group">*/}
                                            {/*    <label for="product-image">Product Featured Image</label>*/}
                                            {/*    <div className="form-control-wrap">*/}
                                            {/*    <Imageselector loading={attachmentUploading} imageUrl={productData.imageUrl ?? ''} onChange={onImageChange} removeImage={removeImage}/>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="form-group">
                                                <label htmlFor="product-image">Product Gallery</label>
                                                <div className="form-control-wrap">
                                                    <GalleryImageSelector sortList={sortGalleryList} loading={galleryUploading} galleryImages={galleryImages}
                                                                   onChange={onImageChange} removeImage={removeGalleryImage}/>
                                                </div>
                                            </div>
                                    </div>

                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="site-off">Featured</label>
                                                <span className="form-note">Is this a featured product?</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" checked={productData.featured} onChange={(event) => setProductData({...productData,featured:!productData.featured})} className="custom-control-input"
                                                           name="reg-public" id="prod-featured"/>
                                                    <label className="custom-control-label"
                                                           htmlFor="prod-featured">{productData.featured ? 'Featured':'Not Featured'}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="site-off">Active</label>
                                                <span className="form-note">Disable product purchase?</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" checked={productData.active} onChange={(event) => setProductData({...productData,active:!productData.active})} className="custom-control-input"
                                                           name="reg-public" id="prod-active"/>
                                                    <label className="custom-control-label"
                                                           htmlFor="prod-active">{productData.active ? 'Active':'Not Active'}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row g-3">
                                        <div className="col-lg-7 offset-lg-5">
                                            <div className="form-group mt-2">
                                                <button type="button" disabled={saving} onClick={submitProduct} className="btn btn-lg btn-primary">{saving ? 'Saving...':'Save'}
                                                </button>
                                            </div>
                                        </div>
                                    </div></>:
                                !productError && <span>Loading...</span>
                                }

                            {productError && <span>{productError}</span>}

                            </div>
                        </div>
                    </div>

                    {product_id && product_id !== undefined && <div className="nk-block">
                        <div className="card">
                            <div className="card-inner">
                                <h5 className="card-title">Adjustment Details</h5>
                                <Adjustments adjustments={productData.adjustments}/>
                            </div>
                        </div>
                    </div>}

                </div>
            </div>
        );
}

export default AddProduct;
