import { API_BASE_URL,API_BASE_URL_ROOT,BASE_URL } from '../helper/globals';
import {store} from '../store';
import axios from 'axios';

const state = () => {
    return store.getState();
}

async function getProduct(product_id){
    return new Promise(function(resolve, reject) {

        axios.get(API_BASE_URL + 'products/'+product_id, { 'params':{
            api_token:state().user.currentUser.apiToken,
            with:'category'
        }})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
};

async function inventoryProducts({page,term,limit}){
    const pageNum = page && page != undefined && page != '' ? page:1;
    const per_page = limit ?? 10;
    const api_token = state().user.currentUser.apiToken;
    const market_id = state().market.marketData.id;
    const offset = (pageNum - 1) * per_page;

    const query_params = {
        api_token,
        offset,
        limit:per_page,
        orderBy:'id',
        sortedBy:'desc',
        with:'category',
        market_id
    }

    if(term){
        query_params.search = 'name:'+term;
        query_params.searchFields = 'name:like';
    }
    return new Promise(function(resolve, reject) {
        axios.get(BASE_URL + 'api/manager/inventory', { 'params':query_params})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
  };

async function copyProduct(product_id){
    return new Promise(function(resolve, reject) {
        axios.post(BASE_URL + 'api/manager/copy/product',{
            api_token:state().user.currentUser.apiToken,
            product_id
        })
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
}

async function copyBatchProduct(batch_products){
    return new Promise(function(resolve, reject) {

        const formData = new FormData();
        formData.append('api_token',state().user.currentUser.apiToken);

        batch_products.map((val) => {
            formData.append('products[]',val.id);
        });

        axios.post(BASE_URL + 'api/manager/copy/product-batch',formData)
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
}

async function copyAllProducts(){
    return new Promise(function(resolve, reject) {

        const formData = new FormData();
        formData.append('api_token',state().user.currentUser.apiToken);

        axios.post(BASE_URL + 'api/manager/copy/product-all',formData)
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
}





export const inventoryService = {
    inventoryProducts,
    getProduct,
    copyProduct,
    copyAllProducts,
    copyBatchProduct
};