import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
const AsideCard = (props) => {
    const {user,market} = useSelector((state) => state);
    return (
        <>
            <div
                                    className="card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg"
                                    data-content="userAside" data-toggle-screen="lg" data-toggle-overlay="true">
                                    <div className="card-inner-group" data-simplebar>
                                        <div className="card-inner">
                                            <div className="user-card">
                                                <div className="user-avatar bg-primary">
                                                <em className="icon ni ni-user-alt"></em>
                                                </div>
                                                <div className="user-info">
                                                    <span className="lead-text">{user.currentUser.name}</span>
                                                    <span className="sub-text">{user.currentUser.phone}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="card-inner">
                                            <div className="user-account-info py-0">
                                                <h6 className="overline-title-alt">Last Login</h6>
                                                <p>06-29-2020 02:39pm</p>
                                                <h6 className="overline-title-alt">Login IP</h6>
                                                <p>192.129.243.28</p>
                                            </div>
    </div>*/}
                                        <div className="card-inner p-0">
                                            <ul className="link-list-menu nav nav-tabs">
                                            
                                                <li>
                                                <Link to="/profile" className={props.tab == 'basic' ? "active":''}>
                                                    <em className="icon ni ni-user-fill-c"></em><span>Personal Infomation</span>
                                                    </Link>
                                                </li>
                                               {market.marketData && market.marketData.active && <li>
                                                <Link to="/market" className={props.tab == 'market' ? "active":''}>
                                                    <em className="icon ni ni-bag-fill"></em><span>Store Infomation</span>
                                                    </Link>
                                                </li>}
                                               
                                            </ul>
                                        </div>
                                    </div>
                                </div>
        </>
    );
}

export default AsideCard;