import React, {useEffect, useState, useReducer, forwardRef, useRef, createRef} from 'react';
import SectionTableHead from "./components/SectionTableHead";
import SectionTableBody from "./components/SectionTableBody";
import {Link,useHistory} from "react-router-dom";
import {sectionService} from "../../../services/service-section";
import Imageselector from "../../components/common/Imageselector";
import {mediaService} from "../../../services/service-media";
import {ReactSortable} from "react-sortablejs";
import {toast} from "react-toastify";

const SortableDiv = React.forwardRef((props, ref) => (
    <div ref={ref} className="display-contact">
        {props.children}
    </div>
));
const Section = () => {
    const history = useHistory();
    const [selectedSection, setSelectedSection] = useState(null);
    const [sections, setSections] = useState([]);
    const [subSections, setSubSections] = useState([]);
    const [editSubSection, setEditSubSection] = useState(null);
    const [attachmentLoading, setAttachmentLoading] = useState(false);
    const [state, forceUpdate] = useReducer(x => x + 1, 0);
    const [savingElements, setSavingElements] = useState(false);
    const ref = createRef();
    useEffect(() => {
        getSections();
    }, []);

    const getSections = () => {
        sectionService.getSections()
            .then((items) => setSections(items))
            .catch((err) => alert(err.message));
    }

    const addNewSection = () => {
        const existSections = subSections;
        const newIndex = existSections.push({
            name: '',
            parameters: '',
            image: '',
            imageUrl: ''
        });
        setSubSections(existSections);
        setEditSubSection(existSections.length - 1);
    }

    const handleChange = (key, value) => {
        const updateSections = subSections.map((item, index) => {
            if (index === editSubSection) {
                return {
                    ...item,
                    [key]: value
                }
            }
            return item;
        });
        setSubSections(updateSections);
    }

    const onImageChange = (event) => {
        if (event.target.files.length <= 0) {
            return;
        }

        setAttachmentLoading(true);
        mediaService.uploadFile(event.target.files[0], 'image')
            .then((res) => {
                const updateSections = subSections.map((item, index) => {
                    if (index === editSubSection) {
                        return {
                            ...item,
                            image: res.uuid,
                            imageUrl: res.media.url
                        }
                    }
                    return item;
                });
                setSubSections(updateSections);
            })
            .finally(() => setAttachmentLoading(false));
    }

    const removeImage = () => {

    }

    const deleteSection = (id) => {
        if(!window.confirm('Are you sure you want to remove this section?')){
            return;
        }
        sectionService.removeSection(id)
            .then(() => {
                toast.success('Section removed');
                getSections()
            });
    }

    const saveSubElements = () => {
        if (savingElements) return;

        setSavingElements(true);
        sectionService.saveSubSections(subSections, selectedSection.id)
            .then((res) => {
                getSections();
                toast.success('Elements Saved');
            })
            .catch((err) => alert(err.message))
            .finally(() => {
                setSavingElements(false);
            });
    }

    const removeElement = (i) => {
        const filtered = subSections.filter((item, index) => index !== i);
        setSubSections(filtered);
    }

    const setNewList = (list) => {
        setSubSections(list);
        //  forceUpdate();
    }

    const setNewSectionsOrder = (data) => {
        setSections(data);
        sectionService.updateSectionsOrder(data).then((res) => {
           toast.success('Sections order updated');
        });
    }

    const edit = (id) => {
        history.push('/createSection/'+id);
    }


    return (
        <div key={state} className="nk-content-inner">
            <div className="nk-content-body">
                <div className="nk-block">
                    <div className="card">
                        <div className="card-aside-wrap">
                            <div className="card-inner card-inner-lg">
                                {selectedSection && selectedSection.content_type === 'sublist'  && <div className="tab-content">
                                    <div className="tab-pane active" id="personal">
                                        <div className="mb-2">
                                            <div className="nk-block-head">
                                                <div className="nk-block-between">
                                                    <div className="nk-block-head-content">
                                                        <h4 className="nk-block-title">Sub Sections
                                                            for {selectedSection.name}</h4>
                                                    </div>
                                                    <div>
                                                        <button className="btn btn-success" onClick={addNewSection}>Add
                                                            Element
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {editSubSection !== null && <div className="nk-block">
                                                <div className="nk-data">
                                                    <div className="row g-3">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Element Name</label>
                                                                <input type="text"
                                                                       value={subSections[editSubSection].name}
                                                                       onChange={(event) => handleChange('name', event.target.value)}
                                                                       className="form-control"
                                                                       placeholder="Add your name..."/>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Element Parameters (JSON) </label>
                                                                <textarea type="text"
                                                                          value={subSections[editSubSection].parameters}
                                                                          onChange={(event) => handleChange('parameters', event.target.value)}
                                                                          className="form-control"
                                                                          placeholder="Your Phone Number"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="row  g-3">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>Image</label>
                                                                <Imageselector
                                                                    imageUrl={subSections[editSubSection].imageUrl}
                                                                    removeImage={removeImage}
                                                                    loading={attachmentLoading}
                                                                    onChange={onImageChange}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <button type="button"
                                                                        onClick={() => setEditSubSection(null)}
                                                                        className="btn btn-success">Done
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>

                                        <div className="mt-5">
                                            <div className="nk-block-head ">
                                                <div className="nk-block-between">
                                                    <div className="nk-block-head-content">
                                                        <h4 className="nk-block-title">Elements List</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="nk-block">
                                                <ReactSortable tag={'ul'} list={subSections}
                                                               setList={(newItems, _, { dragging }) => {
                                                                   // Dragging element is only present if you dragged an item, if it is a click it is null
                                                                   if (!dragging) {
                                                                       return;
                                                                   }

                                                                   setNewList(newItems);
                                                               }}>
                                                    {subSections.map((item, index) =>
                                                        <SectionTableBody removeElement={() => removeElement(index)}
                                                                          editElement={() => {
                                                                              setEditSubSection(index)
                                                                              forceUpdate();
                                                                          }} item={item}/>)}
                                                </ReactSortable>
                                            </div>
                                        </div>
                                        <div className="mt-5">
                                            <button className="btn btn-success" onClick={saveSubElements}
                                                    disabled={savingElements}>{savingElements ? 'Saving...' : 'Save Elements'}</button>
                                        </div>
                                    </div>

                                </div>}
                                {selectedSection && selectedSection.content_type !== 'sublist' && <h4>This is a dynamic section - {selectedSection.content_method}</h4> }
                            </div>


                            <div
                                className="card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg"
                                data-content="userAside" data-toggle-screen="lg" data-toggle-overlay="true">
                                <div className="card-inner-group" data-simplebar>
                                    <div className="card-inner p-0">
                                        <ReactSortable tag={'ul'} list={sections} setList={(newItems, _, { dragging }) => {
                                            // Dragging element is only present if you dragged an item, if it is a click it is null
                                            if (!dragging) {
                                                return;
                                            }

                                            setNewSectionsOrder(newItems);
                                        }}>
                                            {sections.map((item) => <AddBanner selectedSection={selectedSection ? selectedSection.id:null} edit={() => edit(item.id)} delete={() => deleteSection(item.id)} selectSection={() => {
                                                setSelectedSection(item);
                                                setSubSections(item.sub_sections.map((sec) => {
                                                    return {
                                                        ...sec,
                                                        image:sec.has_media ? sec.media[0].custom_properties.uuid:'',
                                                        imageUrl: sec.has_media ? sec.media[0].url : ''
                                                    }
                                                }));
                                            }} sectionName={item.name} sectionId={item.id}/>)}
                                        </ReactSortable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const AddBanner = (props) => {
    return (
        <li className={`section-list-item mb-1 pr-1 ${props.selectedSection && props.selectedSection === props.sectionId && 'active'}`}>
            <Link onClick={props.selectSection} to="/section">
                <span>{props.sectionName}</span>
            </Link>
            <div>
                <button onClick={props.edit} className={'btn btn-info btn-sm mr-1'}>Edit</button>
                <button onClick={props.delete} className={'btn btn-danger btn-sm'}>X</button>
            </div>

        </li>
    )
}
export default Section;
