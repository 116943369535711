import { API_BASE_URL,API_BASE_URL_ROOT,BASE_URL } from '../helper/globals';
import {store} from '../store';
import axios from 'axios';

const state = () => {
    return store.getState();
}


async function getSections(){
    const api_token = state().user.currentUser.apiToken;


    const query_params = {
        api_token,
        orderBy:'order',
        sortedBy:'ASC',
    }

    return new Promise(function(resolve, reject) {
        axios.get(BASE_URL + 'api/admin/sections', { 'params':query_params})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};


async function removeSection(section_id){
    return new Promise(function(resolve, reject) {
        axios.delete(BASE_URL + 'api/admin/sections/'+section_id,{
            params:{
                api_token:state().user.currentUser.apiToken
            }
        })
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
}

async function saveSection(sectionData){

    const api_token = state().user.currentUser.apiToken;

    sectionData.api_token = api_token;

    return new Promise(function(resolve, reject) {
        axios.post(BASE_URL + 'api/admin/sections', sectionData)
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};

async function updateSection(sectionData,section_id){

    const api_token = state().user.currentUser.apiToken;

    sectionData.api_token = api_token;

    return new Promise(function(resolve, reject) {
        axios.put(BASE_URL + 'api/admin/sections/'+section_id, sectionData)
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};

async function updateSectionsOrder(sectionData){

    const postData = {
        api_token: state().user.currentUser.apiToken,
        sections:sectionData
    }

    return new Promise(function(resolve, reject) {
        axios.post(BASE_URL + 'api/admin/update-sections-order', postData)
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};

async function saveSubSections(sectionData,section_id){

    const postData = {
        api_token: state().user.currentUser.apiToken,
        section_id,
        sections:sectionData
    }

    return new Promise(function(resolve, reject) {
        axios.post(BASE_URL + 'api/admin/create-subsections', postData)
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};

async function getSectionData(section_id){
    const api_token = state().user.currentUser.apiToken;
    const query_params = {
        api_token
    }

    return new Promise(function(resolve, reject) {
        axios.get(BASE_URL + 'api/admin/sections/'+section_id, { 'params':query_params})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject(error.response);
            })
    });
};



export const sectionService = {
    getSections,
    saveSection,
    getSectionData,
    saveSubSections,
    removeSection,
    updateSectionsOrder,
    updateSection
};
