import React, {Component} from 'react';
import NkTbItem from "./NkTbItem";
import NkTbHead from "./NkTbHead";

const PrintTable = (props) => {
    return (
        <>

            <div className="offset-md-9 col-md-3 mt-4">
                <table className="table table-striped table-sm">
                    <tbody>
                    <tr>
                        <td className="bold">Shipping</td>
                        <td>Rs. {props.shippingCharge}</td>
                    </tr>
                    <tr>
                        <td><span className="font-weight-bold">Total</span></td>
                        <td><span className="font-weight-bold">Rs. {props.totalCost}</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default PrintTable;