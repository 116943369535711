import React, {Component} from 'react';

const NkTbHead = (props) => {

    
        return (
            <>
                <div className="nk-tb-item nk-tb-head">
                    <div className="nk-tb-col"><span>Order</span></div>
                    <div className="nk-tb-col tb-col-md"><span>Date</span></div>
                    <div className="nk-tb-col"><span className="d-none d-mb-block">Status</span></div>
                    <div className="nk-tb-col tb-col-sm"><span>Customer</span></div>
                    <div className="nk-tb-col tb-col-md"><span>Purchased</span></div>
                    <div className="nk-tb-col"><span>Total</span></div>
                    <div className="nk-tb-col"><span>Action</span></div>
                </div>
            </>
        );
}

export default NkTbHead;
