import React, {useEffect}from 'react'
import {Route,useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
const Loader = ({component: Component}) => {
    const history = useHistory();
    const {currentUser} = useSelector((state) => state.user);

    return (
        <Route render={props => (
            <div className="nk-app-root">
                <div className="nk-main ">
                <div className="nk-wrap nk-wrap-nosidebar">
                    <div className="nk-content ">
                                <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                                        <div className="brand-logo pb-5">
                                            <a href="html/index.html" className="logo-link">
                                                <img className="logo-light logo-img logo-img-lg" src="../../../../assets/images/vendor.png"
                                                     srcSet="../../../../assets/images/vendor.png" alt="logo"/>
                                                    <img className="logo-dark logo-img logo-img-lg"
                                                         src="../../../../assets/images/vendor.png"
                                                         srcSet="../../../../assets/images/vendor.png" alt="logo-dark"/>
                                            </a>
                                        </div>
                                        <div className="nk-block-head">
                                            <div className="nk-block-head-content text-center">
                                                <div class="spinner-border mainloader" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )}
    />
)};

export default Loader;
