import React, {Component} from 'react';

class NkTbItem extends Component {
    render() {
        return (
            <>
                <div className="nk-tb-item">
                    <div className="nk-tb-col nk-tb-col-check">
                        <div className="custom-control custom-control-sm custom-checkbox notext">
                            <input type="checkbox" className="custom-control-input" id="uid1"/>
                            <label className="custom-control-label" htmlFor="uid1"></label>
                        </div>
                    </div>
                    <div className="nk-tb-col tb-col-sm">
                                                <span className="tb-product">
                                                    <img src="./images/product/a.png" alt="" className="thumb"/>
                                                    <span className="title">Pink Fitness Tracker</span>
                                                </span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-sub">UY3749</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-lead">$ 99.49</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-sub">49</span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                        <span className="tb-sub">Fitbit, Tracker</span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                        <div className="asterisk tb-asterisk">
                            <a href="#"><em className="asterisk-off icon ni ni-star"></em><em
                                className="asterisk-on icon ni ni-star-fill"></em></a>
                        </div>
                    </div>
                    <div className="nk-tb-col nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1 my-n1">
                            <li className="mr-n1">
                                <div className="dropdown">
                                    <a href="#" className="dropdown-toggle btn btn-icon btn-trigger"
                                       data-toggle="dropdown"><em
                                        className="icon ni ni-more-h"></em></a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <ul className="link-list-opt no-bdr">
                                            <li><a href="#"><em className="icon ni ni-edit"></em><span>Edit Product</span></a>
                                            </li>
                                            <li><a href="#"><em className="icon ni ni-eye"></em><span>View Product</span></a>
                                            </li>
                                            <li><a href="#"><em
                                                className="icon ni ni-activity-round"></em><span>Product Orders</span></a>
                                            </li>
                                            <li><a href="#"><em className="icon ni ni-trash"></em><span>Remove Product</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

export default NkTbItem;
