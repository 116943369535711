import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import {marketService} from '../../../../services/service-markets';
import {inventoryService} from '../../../../services/service-inventory';
import {toast } from 'react-toastify';
const NkTbItem = (props) => {

    const [copying,setCopying] = useState(false);

    const copyProduct = () => {
        setCopying(true);
        inventoryService.copyProduct(props.product.id)
        .then((res) => {
            toast.success('Product was copied to your product inventory');
            setCopying(false);
        });
    }
   
        return (
            <>
                <div className="nk-tb-item">
                    <div className="nk-tb-col nk-tb-col-check">
                        <div className="custom-control custom-control-sm custom-checkbox notext">
                            <input type="checkbox" checked={props.product.isChecked} onChange={() => props.handleSingleCheck(props.product)} className="custom-control-input" id={'product_'+props.product.id}/>
                            <label className="custom-control-label" htmlFor={'product_'+props.product.id} ></label>
                        </div>
                    </div>
                    <div className="nk-tb-col">
                        <img src={props.product.has_media ? props.product.media[0].icon:'https://system.spacium.store/images/image_default.png'} alt="" width="60" height="60" className="product-thumb thumb"/>
                    </div>
                    <div className="nk-tb-col tb-col-sm">
                         <span className="title">{props.product.name}</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-lead">Rs. {props.product.mrp}</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-sub">{props.product.capacity + ' - '+props.product.unit}</span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                        <span className="title">
                            {props.product.category.name}
                        </span>
                    </div>
                    <div className="nk-tb-col nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1 my-n1">
                            <li className="mr-n1">
                                <button type="button" disabled={copying} title="Copy product" onClick={() => copyProduct(props.product.id)} className="btn btn-success"><em
                                        className="icon ni ni-files"></em><span>{copying ? 'Processing...':'Copy Product'}</span></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
}

export default NkTbItem;
