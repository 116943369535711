import React, {useState,useEffect} from 'react';
import Pagination from "react-js-pagination";
import NkTbHead from "./components/NkTbHead";
import NkTbItem from "./components/NkTbItem";
import AddProduct from "./components/AddProduct";
import { Link,useHistory} from 'react-router-dom';
import {marketService} from '../../../services/service-markets';
import {inventoryService} from '../../../services/service-inventory';
import {useSelector} from 'react-redux';
import queryString from 'query-string';
import {toast } from 'react-toastify';
const Inventory = () => {
    const history = useHistory();
    const {market} = useSelector(state => state);
    const [loading,setLoading] = useState(false);
    const [products,setProducts] = useState([]);
    const [selectAll,SetSelectAll] = useState(false);
    const [batchcopy,setBatchCopy] = useState(false);
    const [allcopy,setAllCopy] = useState(false);
    const [totalProducts,setTotalProducts] = useState(0);
    const params = queryString.parse(window.location.search);
    const [state,setState] = useState({
        limit:20,
        page:params.page && params.page != undefined ? params.page:1,
        term:params.term && params.term != undefined ? params.term:'',
        status:params.status && params.status != undefined ? params.status:'',
    });

    useEffect(() => {
        getOrders();
    }, []);

    useEffect(() => {
        getOrders();
    }, [state]);

    const applyFilter = () => {

        history.push(`/inventory?page=${state.page}&term=${state.term}&status=${state.status}`);
        setState({
            ...state,
            page:1,
        });
    }

    const getOrders = (pagenum = '') => {
        setLoading(true);
        inventoryService.inventoryProducts({page:pagenum ? pagenum:state.page,term:state.term,limit:state.limit})
        .then(res => {
            setLoading(false);
            setProducts(res.products);
            if(res.counts){
            setTotalProducts(res.counts);
            }
        },
        err => {    
            setLoading(false);
            alert(err.message);
        });
    }

    const handlePageChange = (page) => {
        setState({...state,page});
    }

    const handleSelectAll = () => {
        if(selectAll){
          SetSelectAll(false);
          setProducts(products.map((value,index) => {
            value.isChecked = false;
            return value;
          }));
        } else {
          SetSelectAll(true);
          setProducts(products.map((value,index) => {
            value.isChecked = true;
            return value;
          }));
        }
      }
    
      const handleSingleCheck = (item) => {
        setProducts(products.map((value,index) => {
            if(value.id == item.id){
              if(value.isChecked){
                value.isChecked = false;
              } else {
                value.isChecked = true;
              }
              return value;
            }
          return value;
        }));
      }


      const checkedProducts = products.filter((value) => value.isChecked === true);

    const copyBatch = () => {
        setBatchCopy(true);
        inventoryService.copyBatchProduct(checkedProducts)
        .then((res) => {
            toast.success('Products has been copied to your product inventory!')
            setBatchCopy(false);
        });
    }

    const copyAllProducts = () => {
        setAllCopy(true);
        inventoryService.copyAllProducts()
        .then((res) => {
            toast.success('Products has been copied to your product inventory!')
            setAllCopy(false);
        });
    }

    const nextPage = (page) => {
        setState({
            ...state,
            page:state.page+1
        });
    }

    const prevPage = (page) => {
        if(state.page <= 1){
            return;
        }
        setState({
            ...state,
            page:state.page-1
        });
    }
        return (
            <div className="nk-content-inner">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">Inventory Products</h3>
                            </div>
                            <div className="nk-block-head-content">
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                       data-target="pageMenu"><em className="icon ni ni-more-v"></em></a>
                                    <div className="toggle-expand-content" data-content="pageMenu">
                                        <ul className="nk-block-tools g-3">
                                            <li>
                                                <div className="form-control-wrap">
                                                    <div className="form-icon form-icon-right">
                                                        <em className="icon ni ni-search"></em>
                                                    </div>
                                                    <input type="text" value={state.term} onChange={(event) => setState({...state,term:event.target.value})} className="form-control" id="default-04"
                                                           placeholder="Search by name"/>
                                                </div>
                                            </li>
                                            <li className="nk-block-tools-opt">
                                                <button onClick={applyFilter} data-target="addProduct"
                                                   className="toggle btn btn-icon btn-primary d-md-none"><em
                                                    className="icon ni ni-search"></em></button>
                                                <button onClick={applyFilter} data-target="addProduct"
                                                   className="toggle btn btn-primary d-none d-md-inline-flex"><em
                                                    className="icon ni ni-search"></em><span>Filter</span></button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block">
                        <div className="nk-tb-list is-separate mb-3">
                            <div class="row">
                                <div class="col-lg-12">
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <button className="btn btn-primary" onClick={copyAllProducts}><span>Copy All</span></button>
                                        </li>
                                        <li>
                                            <button className="btn btn-success" onClick={copyBatch} disabled={checkedProducts.length <= 0 || batchcopy}><span>{batchcopy ? 'Processing...':'Copy Selected ('+checkedProducts.length+')'}</span></button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <NkTbHead handleAll={handleSelectAll}/>
                            {products.length > 0 && products.map((value,index) => <NkTbItem handleSingleCheck={handleSingleCheck} product={value}/>)}
                        </div>
                        {!loading && totalProducts > 0 && <div>
                            <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            firstPageText="« First Page"
                            lastPageText="Last Page »"
                            nextPageText="Next ⟩"
                            prevPageText="⟨ Prev"
                            activePage={state.page}
                            itemsCountPerPage={state.limit}
                            totalItemsCount={totalProducts}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            />
                        </div>}
                    </div>
                    <AddProduct/>
                </div>
            </div>
        );
}

export default Inventory;
