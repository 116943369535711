import React, {useState} from 'react';
import {Link} from "react-router-dom";
const NkTbItem = (props) => {
        return (
            <>
                <div className="nk-tb-item">

                    <div className="nk-tb-col tb-col-sm">
                         <span className="title">{props.adjustment.id}</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-lead">{props.adjustment.created_at}</span>
                    </div>
                    <div className="nk-tb-col">
                        {props.adjustment.items}
                    </div>
                    <div className="nk-tb-col">
                        {props.adjustment.market_id ? props.adjustment.market?.name:'Warehouse'}
                    </div>
                    <div className="nk-tb-col tb-col-md">
                        {props.adjustment.notes}
                    </div>
                    <div className="nk-tb-col ">
                        <ul className="nk-tb-actions justify-start">
                            <li className="mr-n1">
                                <Link to={props.detail} className="btn btn-gray">Detail</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
}

export default NkTbItem;
