import {API_BASE_URL} from '../../helper/globals';
import {
    SET_LOGIN_PROCESS,
    SET_LOGIN,
    SET_LOGOUT,
    SET_NOTIFICATIONS,
} from '../../store/constants/user';

export const setLoginState = (loginData) => {
    return (dispatch) => {
        localStorage.setItem('user',JSON.stringify(loginData));
        dispatch({
            type: SET_LOGIN,
            data: loginData,
          });   
    }
  };

export const setUserNotifications = (notificationData) => {
    return {
      type: SET_NOTIFICATIONS,
      data: notificationData,
    };
};
  export const setUserLogout = () => {
    return {
      type: SET_LOGOUT,
    };
  };