import { API_BASE_URL,API_BASE_URL_ROOT,BASE_URL } from '../helper/globals';
import {store} from '../store';
import axios from 'axios';

const state = () => {
    return store.getState();
}


async function searchProducts(term){
    return new Promise(function(resolve, reject) {

        axios.get(API_BASE_URL + 'products/search', { 'params':{
                api_token:state().user.currentUser.apiToken,
                term,
                limit:10,
            }})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
};

async function getProductStock(product_id,market_id){
    return new Promise(function(resolve, reject) {

        axios.get(API_BASE_URL + 'products/get-stock', { 'params':{
                api_token:state().user.currentUser.apiToken,
                product_id,
                market_id
            }})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
};


async function getAdjustments({page,term,limit}){
    const pageNum = page && page != undefined && page != '' ? page:1;
    const per_page = limit ?? 10;
    const api_token = state().user.currentUser.apiToken;
    const offset = (pageNum - 1) * per_page;

    const query_params = {
        api_token,
        offset,
        limit:per_page,
        orderBy:'id',
        sortedBy:'desc',
        with:'market'
    }

    return new Promise(function(resolve, reject) {
        axios.get(BASE_URL + 'api/admin/adjustment', { 'params':query_params})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};


async function saveAdjustment(productsData){

    const api_token = state().user.currentUser.apiToken;

    productsData.api_token = api_token;

    return new Promise(function(resolve, reject) {
        axios.post(BASE_URL + 'api/admin/adjustment', productsData)
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};

async function getAdjustmentData(adjustment_id){
    const api_token = state().user.currentUser.apiToken;
    const query_params = {
        api_token,
        with:'details'
    }

    return new Promise(function(resolve, reject) {
        axios.get(BASE_URL + 'api/admin/adjustment/'+adjustment_id, { 'params':query_params})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};



export const adjustmentService = {
    getAdjustments,
    searchProducts,
    saveAdjustment,
    getAdjustmentData,
    getProductStock
};
