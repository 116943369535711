import React, {useState} from 'react';
import {Link} from "react-router-dom";
const NkTbItem = (props) => {
        return (
            <>
                <div className="nk-tb-item">

                    <div className="nk-tb-col tb-col-sm">
                         <span className="title">{ props.transfer.id }</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-lead">{props.transfer.created_at}</span>
                    </div>
                    <div className="nk-tb-col">
                        {props.transfer.items}
                    </div>
                    <div className="nk-tb-col tb-col-md">
                        {props.transfer.notes}
                    </div>
                    <div className="nk-tb-col tb-col-md">
                        Rs. {props.transfer.GrandTotal}
                    </div>
                    <div className="nk-tb-col tb-col-md">
                        {props.transfer.status === 0 ? 'Pending':'Completed'}
                    </div>
                    <div className="nk-tb-col ">
                        <ul className="nk-tb-actions justify-start">
                            <li className="mr-n1">

                                <Link to={props.detail} className="btn btn-gray">Detail</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
}

export default NkTbItem;
