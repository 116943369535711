import React,{useEffect,useState} from 'react'
import {Route,useHistory} from 'react-router-dom';
import Home from "../main/home/Home";
import Loader from "../initial/loader";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import Orders from "../main/orders/Orders";
import Products from "../main/products/Products";
import Users from "../main/users/Users";
import Inventory from "../main/inventory/Inventory";
import Customer from "../main/customer/Customer";
import Profile from "../main/profile/Profile";
import Market from "../main/profile/Market";
import CreateStore from "../auth/createStore";
import AddProduct from '../main/store/AddProduct';
import OrderDetail from "../main/orders/OrderDetail";
import {useSelector,useDispatch} from 'react-redux';
import {marketService} from '../../services/service-markets';
import {setMarketState} from '../../store/actions/market.actions';
import AddAdjustment from "../main/adjustment/AddAdjustment";
import CreateTransfer from "../main/transfer/CreateTransfer";
import Adjustment from "../main/adjustment/Adjustment";
import AdjustmentDetail from "../main/adjustment/AdjustmentDetail";
import TransferList from "../main/transfer/TransferList";
import TransferDetail from "../main/transfer/TransferDetail";
import Section from "../main/section/Section";
import CreateSection from "../main/section/CreateSection";
import EditUser from "../main/users/EditUser";
import ExpiringStocks from "../main/expiringStocks/ExpiringStocks";



const MainRoute = ({component: Component}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {user,market} = useSelector((state) => state);
    const [loading,setLoading] = useState(true);
    useEffect(() => {
        if(!user.currentUser?.id){
            history.push('login');
        } else {
           initialData();
        }
    }, [])


    const initialData = () => {
        marketService.getMarkets({api_token:user.currentUser.apiToken}).then((data) => {
                setLoading(false);
                if(data){
                        dispatch(setMarketState(data));
                } else {
                    //window.location.href = 'add-store';
                }

        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }

    return (
        <Route
            render={props => (
                <>
                {loading ?
                <Loader/>:
                 <div className="nk-app-root">
                 <div className="nk-main ">
                     <Sidebar/>
                     <div className="nk-wrap ">
                         <Header/>
                         <div className="nk-content ">
                             <div className="container-fluid">
                                 <Route exact path="/" component={Home}/>
                                 <Route exact path="/orders" component={Orders}/>
                                 <Route exact path="/orderDetail/:id" component={OrderDetail}/>
                                 <Route exact path="/products" component={Products}/>
                                 <Route exact path="/expiring" component={ExpiringStocks}/>
                                 <Route exact path="/users" component={Users}/>
                                 <Route exact path="/users/editUser/:id" component={EditUser}/>
                                 <Route exact path="/inventory" component={Inventory}/>
                                 <Route exact path="/customer" component={Customer}/>
                                 <Route exact path="/products/addProduct" component={AddProduct}/>
                                 <Route exact path="/products/addProduct/:id" component={AddProduct}/>
                                 <Route exact path="/profile" component={Profile}/>
                                 <Route exact path="/adjustments" component={Adjustment}/>
                                 <Route exact path="/addAdjustment" component={AddAdjustment}/>
                                 <Route exact path="/adjustmentDetail/:id" component={AdjustmentDetail}/>
                                 <Route exact path="/transferList" component={TransferList}/>
                                 <Route exact path="/createTransfer" component={CreateTransfer}/>
                                 <Route exact path="/transferDetail/:id" component={TransferDetail}/>
                                 <Route exact path="/section" component={Section}/>
                                 <Route exact path="/createSection" component={CreateSection}/>
                                 <Route exact path="/createSection/:id" component={CreateSection}/>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
                }
                </>
            )}
        />
    )
};

export default MainRoute;
