import React, {Component} from 'react';

const Pagination = (props) => {


        return (
            <div className="card">
                <div className="card-inner">
                    <div className="nk-block-between-md g-3">
                        <div className="g">
                            <ul className="pagination justify-content-center justify-content-md-start">
                                <li className="page-item"><span className="page-link" onClick={props.prev}><em
                                    className="icon ni ni-chevrons-left"></em> Previous</span></li>
                                <li className="page-item"><span className="page-link" onClick={props.next}>Next <em
                                    className="icon ni ni-chevrons-right"></em></span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default Pagination;
