import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {marketService} from "../../../../services/service-markets";
const NkTbItem = (props) => {

        const statusUpdate = () => {
            marketService.updateProductStatus(props.product.active ? false:true,props.product.id)
                .then((res) => props.refreshList());
        }

        return (
            <>
                <div className="nk-tb-item">

                    <div className="nk-tb-col">
                        <img src={props.product.has_media ? props.product.media[0].icon:''} alt="" width="60" height="60" className="product-thumb thumb"/>
                    </div>
                    <div className="nk-tb-col tb-col-sm">
                         <span className="title"><Link to={"/products/addProduct/"+props.product.id}>{props.product.name}</Link></span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-lead"><small>M.R.P {props.product.price}</small><br/><span className={'text-primary'}>Selling: <b>{props.product.final_price}</b></span></span>
                    </div>
                    { !props.product.variable ? <><div className="nk-tb-col">
                        <span className="tb-sub">{props.product.capacity + ' - '+props.product.unit}</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-sub">

                            <div className="custom-control custom-switch">
                                <input type="checkbox" onChange={statusUpdate} defaultChecked={props.product.active} className="custom-control-input" id={"customSwitch"+props.product.id}/>
                                    <label className="custom-control-label" htmlFor={"customSwitch"+props.product.id}>{props.product.active ? <span className="text-success">Active</span>:
                                        <span className="text-danger">Disabled</span>}</label>
                            </div>

                        </span>
                    </div></>
                    :<>
                        { props.product.variations.length > 0 && <><div className="nk-tb-col">
                            <span className="tb-sub">{props.product.variations[0].capacity + ' - '+props.product.variations[0].unit}</span>
                        </div>
                            <div className="nk-tb-col">
                                <span className="tb-sub">{props.product.variations[0].stock}</span>
                            </div></>}</>
                    }
                    <div className="nk-tb-col tb-col-md">
                        <span className="title">
                            {props.product.category.name}
                        </span>
                    </div>
                    <div className="nk-tb-col nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1 my-n1">
                            <li className="mr-n1">
                                <div className="dropdown">
                                    <a href="#" className="dropdown-toggle btn btn-icon btn-trigger"
                                       data-toggle="dropdown"><em
                                        className="icon ni ni-more-h"></em></a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <ul className="link-list-opt no-bdr">
                                            <li><Link to={"/products/addProduct/"+props.product.id}>
                                                <em className="icon ni ni-edit"></em><span>Edit Product</span>
                                                </Link>
                                            </li>
                                            <li><a onClick={() => props.deleteProduct(props.product.id)}><em className="icon ni ni-trash"></em><span>Remove Product</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
}

export default NkTbItem;
