import React, {Component, useEffect, useState} from 'react';
import PrintTable from "./components/PrintTable";
import {useSelector} from "react-redux";
import AutoCompleteProducts from "../../components/inputs/AutoCompleteProducts";
import {useHistory} from "react-router-dom";
import {adjustmentService} from "../../../services/service-adjustment";
import {toast} from "react-toastify";
import axios from "axios";
import {API_BASE_URL} from "../../../helper/globals";
import NkTbHead from "./components/NkTbHead";
import NkTbItem from "./components/NkTbItem";
import {transferService} from "../../../services/service-transfers";

const CreateTransfer =()=> {
    const {user,market} = useSelector(state => state);
    const history = useHistory();
    const [selectedProducts,setSelectedProducts] = useState([]);
    const [notes,setNotes] = useState('');
    const [saving,setSaving] = useState(false);
    const [selectedFile,setSelectedFile] = useState(null);
    const [uploading,setUploading] = useState(false);
    const [invalidProducts,setInvalidProducts] = useState([]);
    const [shippingCharge,setShippingCharge] = useState(0);
    const [toMarket,setToMarket] = useState(null);
    const [fromMarket,setFromMarket] = useState(null);
    const onFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const submitTransfer = () => {
        if(saving){
            return false;
        }

        if(selectedProducts.length <= 0 || !validateSelectedProducts()){
            return true;
        }

        if(!toMarket){
            toast.error('Please select destination market');
            return false;
        }

        const postData = {
            notes,
            to_market:toMarket,
            from_market:fromMarket,
            shipping_charge:shippingCharge,
            products:selectedProducts
        }
        setSaving(true);
        transferService.saveTransfer(postData)
            .then((res) => {
                toast.success("Record Saved");
                history.push("/transferList");
            })
            .catch((err) => {
                toast.error('Something wrong');
            })
            .finally(() =>  setSaving(false));


    }

    const startUpload = () => {
        setUploading(true);
        const formData = new FormData();
        formData.append(
            "file",
            selectedFile,
            selectedFile.name
        );
        formData.append('api_token',user.currentUser.apiToken);
        axios.post(API_BASE_URL+'transfer/excel', formData)
            .then((res) => {
                const response = res.data;
                const products = response.data.products;
                const invalid_products = response.data.invalid_products;
                setInvalidProducts(invalid_products);

                const filtered_products = products.filter((item) => !isDuplicate(item.code));

                products.map((item) => {
                    if(isDuplicate(item.code)){
                        item.reason = 'Already Present';
                        invalid_products.push(item);
                    }
                })
                const prods = filtered_products.map((item,index) => {
                    return {
                        product_id: item.id,
                        product_variation_id:item.is_variation ? item.variation_id:null,
                        code:item.code,
                        name:item.name,
                        unit:item.unit,
                        capacity:item.capacity,
                        stock:parseInt(item.admin_stock),
                        price:item.price,
                        available_stock:item.available_stock ? item.available_stock.quantity:0,
                        type:'increase'
                    };
                });
                setSelectedProducts(selectedProducts.concat(prods));
                setSelectedFile(null);
            })
            .catch((err) => {
                const error = err.response.data;
                toast.error(error.message);
            })
            .finally(() => setUploading(false));
    }

    const isDuplicate = (code) => {
        const found = selectedProducts.filter((item) => item.code === code);
        if(found.length > 0){
            return true;
        }

        return false;
    }

    const chosenValue = (item) => {
        let updatedProducts = selectedProducts.concat({
            product_id: item.id,
            product_variation_id:item.is_variation ? item.variation_id:null,
            code:item.code,
            name:item.name,
            unit:item.unit,
            price:item.price,
            capacity:item.capacity,
            available_stock:parseInt(item.stock),
            stock:0,
            type:'increase'
        });
        setSelectedProducts(updatedProducts);
    }

    const removeProduct = (index) => {
        setSelectedProducts(selectedProducts.filter((item,c_index) => c_index != index));
    }

    const validateSelectedProducts = () => {
        let valid = true;
        selectedProducts.map((item) => {
            if(item.stock <= 0){
                valid = false;
            }
        });
        return valid;
    }

    const readyToSubmit = () => {
        if(selectedProducts.length > 0 && validateSelectedProducts()){
            return true;
        }
        return false;
    }

    const updateStock = (val,index) => {

        const updatingProduct = selectedProducts[index];

        if(val > updatingProduct.available_stock){
            toast.error('Stock not available');
            return false;
        }

        const updatedProducts = selectedProducts.map((item,i) => {
            if(index === i){
                return {
                    ...item,
                    stock:val
                }
            }
            return item;
        });
        setSelectedProducts(updatedProducts);
    }

    const priceChange = (val,index) => {
        const updatedProducts = selectedProducts.map((item,i) => {
            if(index === i){
                return {
                    ...item,
                    price:val
                }
            }
            return item;
        });
        setSelectedProducts(updatedProducts);
    }

    const updateType = (val,index) => {
        const updatedProducts = selectedProducts.map((item,i) => {
            if(index === i){
                return {
                    ...item,
                    type:val
                }
            }
            return item;
        });
        setSelectedProducts(updatedProducts);
    }

    const totalCost = () => {
        let total_cost = 0;
        selectedProducts.map((prod) => {
            total_cost += parseInt(prod.price) * parseInt(prod.stock);
        });
        total_cost += parseInt(shippingCharge);
        return total_cost;
    }

        return (
            <div className="nk-content-inner">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-head-content">
                            <h5 className="nk-block-title">Create Transfer</h5>
                            <div className="nk-block-des">
                                <p>Stock Adjustment</p>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block">
                        <div className="card p-2">
                            <div className="row g-3">
                                <div className="col-mb-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="sale-price">From</label>
                                        <div className="form-control-wrap">
                                            <select onChange={(e) => setFromMarket(e.target.value)} className={'form-control'}>
                                                <option>Warehouse</option>
                                                {market.marketData.map((market,index) => <option value={market.id}>{market.name}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-mb-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="sale-price">To Shop:</label>
                                        <div className="form-control-wrap">
                                            <select onChange={(e) => setToMarket(e.target.value)} className={'form-control'}>
                                                <option>Select Shop</option>
                                                {market.marketData.map((market,index) => <option value={market.id}>{market.name}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                    <div className="col-mb-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="sale-price">Product Search</label>
                                            <div className="form-control-wrap">
                                                {/*<input type="text" className="form-control" id="sale-price"/>*/}
                                                <AutoCompleteProducts selected_value={chosenValue}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-mb-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="sale-price">Upload Excel File</label>
                                            {!selectedFile && <div className="form-control-wrap">
                                                <div className="custom-file">
                                                    <input type="file" onChange={onFileChange} className="custom-file-input"  id="customFile"/>
                                                    <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                                </div>
                                            </div>}

                                            {
                                                selectedFile &&
                                                <div className="form-control-wrap">
                                                    <div className="btn-group" aria-label="Basic example">
                                                        <button type="button" className="btn btn-outline-gray">{selectedFile.name}</button>
                                                        <button disabled={uploading} type="button" onClick={startUpload} className="btn btn-success">{uploading ? 'Uploading...':'Upload File'}</button>
                                                    </div>
                                                    <button disabled={uploading} type="button" onClick={() => setSelectedFile(null)} className="ml-2 btn btn-danger">Remove</button>
                                                </div>
                                            }

                                        </div>
                                    </div>

                                    <div className="col-mb-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="sale-price">Shipping Charge</label>
                                            <div className="form-control-wrap">
                                                <input type="text" onChange={(e) => setShippingCharge(e.target.value)} className="form-control" id="sale-price"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-mb-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="sale-price">Notes</label>
                                            <div className="form-control-wrap">
                                            <textarea onChange={(e) => setNotes(e.target.value)} className="form-control" >
                                            </textarea>
                                            </div>
                                        </div>
                                    </div>


                            </div>
                        </div>
                        {invalidProducts.length > 0 && <div className="card p-2 mb-2">
                            <div className="nk-block">
                                <div className="nk-block-head nk-block-head-sm">
                                    <div className="nk-block-head-content">
                                        <h5 className="nk-block-title text-danger">Error Found</h5>
                                        <div className="nk-block-des">
                                            <p>Please fix below products</p>
                                        </div>
                                    </div>
                                </div>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">Line</th>
                                        <th scope="col">Code</th>
                                        <th scope="col">Stock</th>
                                        <th scope="col">Reason</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {invalidProducts.map((item,index) => {
                                        return <tr>
                                            <th scope="row">{item.line_number}</th>
                                            <td>{item.code}</td>
                                            <td>{item.stock}</td>
                                            <td>{item.reason}</td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                        <div className="card p-2 mb-2">
                            <div className="nk-block">
                                <div className="nk-tb-list is-separate mb-3">
                                    <NkTbHead/>
                                    {selectedProducts.length > 0 && selectedProducts.map((product,index) =>
                                        <NkTbItem
                                            removeProduct={() => removeProduct(index)}
                                            onStockChange={(val) => updateStock(val,index)}
                                            onPriceChange={(val) => priceChange(val,index)}
                                            product={product}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        <PrintTable shippingCharge={shippingCharge} totalCost={totalCost()}/>

                        <div className="float-right mr-1">
                            <button onClick={submitTransfer} className="btn btn-primary">
                                {saving ? 'Saving...':'Submit'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default CreateTransfer;
