import React, {Component} from 'react';

const NkTbHead = (props) => {
        return (
            <>
                <div className="nk-tb-item nk-tb-head">
                    <div className="nk-tb-col"><span>Product Code</span></div>
                    <div className="nk-tb-col tb-col-sm"><span>Product</span></div>
                    <div className="nk-tb-col"><span>Stock</span></div>
                    <div className="nk-tb-col"><span>M.R.P</span></div>
                    <div className="nk-tb-col"><span>Total</span></div>
                </div>
            </>
        );
}

export default NkTbHead;