import React, {Component, useEffect, useState} from 'react';
import NkTbHead from "./components/NkTbHead";
import NkTbItem from "./components/NkTbItem";
import AutoCompleteProducts from "../../components/inputs/AutoCompleteProducts";
import {adjustmentService} from "../../../services/service-adjustment";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {API_BASE_URL} from "../../../helper/globals";
import axios from "axios";

const AddAdjustment =()=> {
    const history = useHistory();
    const {user,market} = useSelector(state => state);
    const [selectedProducts,setSelectedProducts] = useState([]);
    const [notes,setNotes] = useState('');
    const [saving,setSaving] = useState(false);
    const [selectedFile,setSelectedFile] = useState(null);
    const [uploading,setUploading] = useState(false);
    const [invalidProducts,setInvalidProducts] = useState([]);
    const [toMarket,setToMarket] = useState('');
    useEffect(() => {

    });


    const onFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const submitAdjustment = () => {
        if(selectedProducts.length <= 0 || !validateSelectedProducts()){
            return true;
        }
        const postData = {
            notes,
            market_id:toMarket,
            products:selectedProducts
        }
        adjustmentService.saveAdjustment(postData)
            .then((res) => {
                toast.success("Record Saved");
                history.goBack();
            })
            .catch((err) => {
               toast.error('Something wrong');
            });


    }

    const startUpload = () => {
        setUploading(true);
        const formData = new FormData();
        formData.append(
            "file",
            selectedFile,
            selectedFile.name
        );
        formData.append('api_token',user.currentUser.apiToken);
        axios.post(API_BASE_URL+'adjustment/excel', formData)
            .then((res) => {
                const response = res.data;
                const products = response.data.products;
                const invalid_products = response.data.invalid_products;
                setInvalidProducts(invalid_products);

                const filtered_products = products.filter((item) => !isDuplicate(item.code));

                products.map((item) => {
                    if(isDuplicate(item.code)){
                        item.reason = 'Already Present';
                        item.type = item.type === 1 ? 'Increase':'Decrease';
                        invalid_products.push(item);
                    }
                })
                const prods = filtered_products.map((item,index) => {
                        return {
                            product_id: item.id,
                            product_variation_id:item.is_variation ? item.variation_id:null,
                            code:item.code,
                            name:item.name,
                            unit:item.unit,
                            capacity:item.capacity,
                            stock:item.stock,
                            type:'increase'
                        };
                });
                setSelectedProducts(selectedProducts.concat(prods));
                setSelectedFile(null);
            })
            .catch((err) => {
               const error = err.response.data;
               toast.error(error.message);
            })
            .finally(() => setUploading(false));
    }

    const isDuplicate = (code) => {
        const found = selectedProducts.filter((item) => item.code === code);
        if(found.length > 0){
            return true;
        }

        return false;
    }

    const chosenValue = (item) => {
        let updatedProducts = selectedProducts.concat({
            product_id: item.id,
            product_variation_id:item.is_variation ? item.variation_id:null,
            code:item.code,
            name:item.name,
            unit:item.unit,
            capacity:item.capacity,
            stock:0,
            type:'increase'
        });
        setSelectedProducts(updatedProducts);
    }

    const removeProduct = (index) => {
        setSelectedProducts(selectedProducts.filter((item,c_index) => c_index != index));
    }

    const validateSelectedProducts = () => {
        let valid = true;
        selectedProducts.map((item) => {
            if(item.stock <= 0){
                valid = false;
            }
        });
        return valid;
    }

    const readyToSubmit = () => {
        if(selectedProducts.length > 0 && validateSelectedProducts()){
            return true;
        }
        return false;
    }


    const updateStock = (val,index) => {
        const updatedProducts = selectedProducts.map((item,i) => {
           if(index === i){
               return {
                   ...item,
                   stock:val
               }
           }
           return item;
        });
        setSelectedProducts(updatedProducts);
    }

    const updateType = (val,index) => {
        const updatedProducts = selectedProducts.map((item,i) => {
            if(index === i){
                return {
                    ...item,
                    type:val
                }
            }
            return item;
        });
        setSelectedProducts(updatedProducts);
    }
        return (
            <div className="nk-content-inner">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-head-content">
                            <h5 className="nk-block-title">Add Adjustments</h5>
                            <div className="nk-block-des">
                                <p>Stock Adjustment</p>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block">
                        <div className="card p-2">
                            <div className="row g-3">
                                <div className="col-mb-12">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="sale-price">To Shop:</label>
                                        <div className="form-control-wrap">
                                            <select onChange={(e) => setToMarket(e.target.value)} className={'form-control'}>
                                                <option>Select Shop</option>
                                                {market.marketData.map((market,index) => <option value={market.id}>{market.name}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-mb-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="sale-price">Product Search</label>
                                        <div className="form-control-wrap">
                                            {/*<input type="text" className="form-control" id="sale-price"/>*/}
                                            <AutoCompleteProducts selected_value={chosenValue}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-mb-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="sale-price">Upload Excel File</label>
                                        {!selectedFile && <div className="form-control-wrap">
                                                <div className="custom-file">
                                                    <input type="file" onChange={onFileChange} className="custom-file-input"  id="customFile"/>
                                                        <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                                </div>
                                        </div>}

                                        {
                                            selectedFile &&
                                            <div className="form-control-wrap">
                                                <div className="btn-group" aria-label="Basic example">
                                                    <button type="button" className="btn btn-outline-gray">{selectedFile.name}</button>
                                                    <button disabled={uploading} type="button" onClick={startUpload} className="btn btn-success">{uploading ? 'Uploading...':'Upload File'}</button>
                                                </div>
                                                <button disabled={uploading} type="button" onClick={() => setSelectedFile(null)} className="ml-2 btn btn-danger">Remove</button>
                                            </div>
                                        }

                                    </div>
                                </div>


                                <div className="col-mb-12">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="sale-price">Notes</label>
                                        <div className="form-control-wrap">
                                            <textarea onChange={(e) => setNotes(e.target.value)} className="form-control" >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {invalidProducts.length > 0 && <div className="card p-2 mb-2">
                            <div className="nk-block">
                                <div className="nk-block-head nk-block-head-sm">
                                    <div className="nk-block-head-content">
                                        <h5 className="nk-block-title text-danger">Error Found</h5>
                                        <div className="nk-block-des">
                                            <p>Please fix below products</p>
                                        </div>
                                    </div>
                                </div>
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Line</th>
                                            <th scope="col">Code</th>
                                            <th scope="col">Stock</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Reason</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {invalidProducts.map((item,index) => {
                                            return <tr>
                                                <th scope="row">{item.line_number}</th>
                                                <td>{item.code}</td>
                                                <td>{item.stock}</td>
                                                <td>{item.type}</td>
                                                <td>{item.reason}</td>
                                            </tr>
                                        })}
                                        </tbody>
                                    </table>
                            </div>
                        </div>}


                        <div className="card p-2 mb-2">
                            <div className="nk-block">
                                <div className="nk-tb-list is-separate mb-3">
                                    {/*<div className="row">*/}
                                    {/*    <div className="col-lg-12">*/}
                                    {/*        <ul className="nk-block-tools g-3">*/}
                                    {/*            <li>*/}
                                    {/*                <button className="btn btn-primary" onClick={copyAllProducts}><span>Copy All</span>*/}
                                    {/*                </button>*/}
                                    {/*            </li>*/}
                                    {/*            <li>*/}
                                    {/*                <button className="btn btn-success" onClick={copyBatch}*/}
                                    {/*                        disabled={checkedProducts.length <= 0 || batchcopy}>*/}
                                    {/*                    <span>{batchcopy ? 'Processing...' : 'Copy Selected (' + checkedProducts.length + ')'}</span>*/}
                                    {/*                </button>*/}
                                    {/*            </li>*/}
                                    {/*        </ul>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <NkTbHead />
                                    {selectedProducts.length > 0 && selectedProducts.map((item,index) =>
                                        <NkTbItem
                                        product={item}
                                        onStockChange={(val) => updateStock(val,index)}
                                        onTypeChange={(val) => updateType(val,index)}
                                        removeProduct={() => removeProduct(index)}
                                        />
                                    )}
                                </div>

                                <button disabled={!readyToSubmit()} onClick={submitAdjustment} className={'btn btn-success mt-4 float-right'}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}
export default AddAdjustment;
