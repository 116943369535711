import React, {Component} from 'react';

const NkTbHead = (props) => {
        return (
            <>
                <div className="nk-tb-item nk-tb-head">
                    <div className="nk-tb-col"><span>ID</span></div>
                    <div className="nk-tb-col tb-col-sm"><span>Date</span></div>
                    <div className="nk-tb-col"><span>Items</span></div>
                    <div className="nk-tb-col"><span>Notes</span></div>
                    <div className="nk-tb-col"><span>Grand Total</span></div>
                    <div className="nk-tb-col"><span>Status</span></div>
                    <div className="nk-tb-col">#</div>
                </div>
            </>
        );
}

export default NkTbHead;