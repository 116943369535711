import React, {Component} from 'react';
import NkTbItem from './NkTbItem';

const StatisticsColTwo = (props) => {
        return (
            <>
                <div className="col-xxl-12 col-md-12">
                    <div className="card card-full">
                        <div className="card-inner">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h6 className="title">Recent Orders</h6>
                                </div>
                            </div>
                        </div>
                        <div className="nk-tb-list mt-n2">
                            <div className="nk-tb-item nk-tb-head">
                                <div className="nk-tb-col"><span>Order</span></div>
                                <div className="nk-tb-col tb-col-md"><span>Date</span></div>
                                <div className="nk-tb-col"><span className="d-none d-mb-block">Status</span></div>
                                <div className="nk-tb-col tb-col-sm"><span>Customer</span></div>
                                <div className="nk-tb-col tb-col-md"><span>Purchased</span></div>
                                <div className="nk-tb-col"><span>Total</span></div>
                            </div>
                            {props.orders.length > 0 && props.orders.map((value,index) => <NkTbItem order={value}/>)}
                        </div>
                    </div>
                </div>
                
            </>
        );
}

export default StatisticsColTwo;
