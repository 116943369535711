import React, {Component} from 'react';
import {Switch, BrowserRouter} from 'react-router-dom';
import './App.css';
import MainRoute from "./views/components/MainRoute";
import Home from "./views/main/home/Home";
import Login from "./views/auth/login/Login";
import AuthRoute from "./views/components/AuthRoute";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ready: true
    }
  }

  render() {

    if (!this.state.ready) {
      return <div/>;
    }
    return (
        <>
        <BrowserRouter>
          <Switch>
            <AuthRoute path="/login" name="login" component={Login}/>
            <MainRoute path="/" name="home" component={Home}/>
          </Switch>
        </BrowserRouter>
        <ToastContainer />
    </>
    );
  }
}

export default App;
