import React, {useState,useEffect} from 'react';
import StatisticsColThree from "./components/StatisticsColThree";
import StatisticsColTwo from "./components/StatisticsColTwo";
import PageHeading from "../../components/header/PageHeading";
import {userService} from '../../../services/service-user';
import {marketService} from '../../../services/service-markets';
const Home = () =>{
    const [countData,setCountData] = useState('');
    const [orders,setOrders] = useState([]);

    useEffect(() => {
        getCounts();
        getRecentOrders();
    }, []);

    const getCounts = () => {
        userService.getCounts()
        .then((res) => {
            setCountData(res);
        });
    }

    const getRecentOrders = () => {
        marketService.getOrders({page:1,limit:10,term:'',status:''})
        .then((res) => {
            setOrders(res.orders);
        });
    }
        return (
            <div className="nk-content-inner">
                <div className="nk-content-body">
                    <PageHeading name={'Dashboard'}/>
                    <div className="nk-block">
                        <div className="row g-gs">
                            {/*<StatisticsColFour counts={countData}/>*/}
                            <StatisticsColThree/>
                            <StatisticsColTwo orders={orders}/>
                        </div>
                    </div>
                </div>
            </div>

        );
}

export default Home;
